import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {PageLayout, ProductHighlights, PerksBanner, Banner, LogoBanner, FAQ, ParagraphList} from '/src/components'

const PRODUCTS = [
	['seo_planning_templates', 'seo_planning_costs'],
	['seo_planning_gaps', 'seo_planning_notifications'],
	['seo_planning_in_app'],
	['seo_planning_chat'],
]

const PERKS = [
	[
		{type: 'monthly', hide_cta: true},
		{type: 'lowest_price', hide_cta: true},
		{type: 'integrations', hide_cta: true},
	],
]

const InnerPage = ({data}) => {
	const {faq, products, paragraphs} = formatData({data})
	const horecaLogos = data.sector.clients.map(client => client.logo)

	return (
		<>
			<ProductHighlights highlights={products} items={PRODUCTS[0]} coloringOffsetAmount={1} marginTop={100} />
			<PerksBanner type="no_hidden_costs" highlights={PERKS[0]} marginTop={100} />
			<ProductHighlights highlights={products} items={PRODUCTS[1]} coloringOffsetAmount={3} marginTop={120} />
			<LogoBanner type="seo-planning-page" logos={horecaLogos} marginTop={120} />
			<ProductHighlights highlights={products} items={PRODUCTS[2]} marginTop={140} />
			<FAQ items={faq} marginTop={100} />
			<ProductHighlights highlights={products} items={PRODUCTS[3]} coloringOffsetAmount={2} marginTop={100} />
			<ParagraphList items={paragraphs} marginTop={120} />
			<Banner type="try_for_free" marginTop={120} />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})
	const pageName = 'employee-planning-for-hospitality'
	const bannerProps = {titleLarge: true}
	return (
		<PageLayout name={pageName} banner={pageName} data={data} bannerProps={bannerProps} {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "employee-planning-for-hospitality"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					cta
					name
					preview {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					label
					buttonLink
					buttonText
					paragraph {
						paragraph
					}
				}
			}
		}

		contentfulSector(node_locale: {eq: $locale}, slug: {eq: "horeca"}) {
			clients {
				logo {
					gatsbyImageData
				}
			}
		}

		contentfulTextPage(node_locale: {eq: $locale}, slug: {eq: "personeelsplanning-horeca"}) {
			textBlocks {
				text {
					raw
				}
				title
			}
		}

		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "planning-seo"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
