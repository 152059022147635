import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {ProductTiles, VideoBanner, PerksBanner, ProductHighlights, ClientLogosBanner, ProductPageLayout, Quotes} from '/src/components'

const PERKS = [
	[
		{type: 'monthly', hide_cta: true},
		{type: 'lowest_price', hide_cta: true},
		{type: 'integrations', hide_cta: true},
	],
]

const PRODUCTS = [['articles'], ['automated_onboarding', 'skills', 'handbook', 'happy_support', 'upload_pdfs', 'training']]

const PageChildren = ({data}) => {
	const {quotes, tiles, clientLogos, products} = formatData({data})

	return (
		<>
			<PerksBanner type="no_hidden_costs" highlights={PERKS[0]} marginTop={0} />
			<ProductHighlights highlights={products} items={PRODUCTS[0]} coloringOffsetAmount={1} />
			<Quotes items={quotes?.[0]} />
			<ProductHighlights highlights={products} items={PRODUCTS[1]} coloringOffsetAmount={2} />
			<ClientLogosBanner logos={clientLogos} />
			<ProductTiles type="knowledge_base" items={tiles} />
		</>
	)
}

const Page = ({data}) => {
	const {seo, faq} = formatData({data})

	return (
		<ProductPageLayout name="onboarding" {...seo} faq={faq}>
			<PageChildren data={data} />
		</ProductPageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					highlights
					shortHighlights
					cta
					name
					asset {
						gatsbyImageData
					}
					preview {
						gatsbyImageData
					}
					previews {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					label
					buttonLink
					buttonText
					paragraph {
						paragraph
					}
				}
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
		allContentfulClient(sort: {order: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logo {
						gatsbyImageData
					}
				}
			}
		}
		contentfulPage(slug: {eq: "training"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
			productTiles {
				title
				subtitle
				icon
			}
			quotes {
				name
				jobTitle
				image {
					gatsbyImageData
				}
				quote {
					quote
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
