import {intercomUrl, isDev} from '/src/constants'

// Don't import below two options at all or through barrel, since there seems to be a race condition
// preventing the DD element from showing.
import {handleToggleIgnoreTranslations} from '/src/helpers/ignore_translations'
const showTranslationKeys = !process.env.GATSBY_IS_PROD

export const PRODUCT_PAGES_TAXONOMY = [
	{title: 'menu.planning', link: '/rooster'},
	{title: 'menu.hours', link: '/urenregistratie'},
	{title: 'menu.clocking', link: '/kloksysteem'},
	{title: 'menu.communication', link: '/communicatie'},
	{title: 'menu.onboarding', link: '/inwerken'},
	{title: 'menu.mobile_app', link: '/mobiele-app'},
	{title: 'menu.financial', link: '/financiele-analyse'},
	{title: 'menu.exports', link: '/excel-exporteren'},
	{title: 'menu.all_features', link: '/tools'},
]

export const taxonomy = [
	{
		title: 'menu.product',
		link: '/rooster',
		items: [
			...PRODUCT_PAGES_TAXONOMY,
			{
				title: 'menu.integrations',
				link: '/partners',
				children: [
					{title: 'Nmbrs', link: '/partners/visma-nmbrs'},
					{title: 'Loket', link: '/partners/loket-nl'},
					{title: 'Lightspeed', link: '/partners/lightspeed-k-series'},
					{title: 'menu.see_all_integrations', link: '/partners'},
					{title: 'menu.partner-worden', link: '/partner-worden'},
				],
			},
		],
	},
	{
		title: 'menu.more',
		link: '/partners',
		items: [
			{title: 'menu.integrations', link: '/partners'},
			{title: 'menu.help', link: intercomUrl},
			{title: 'menu.blog', link: '/blog'},
			{title: 'menu.team', link: '/team'},
			{title: 'menu.vacancies', link: '/vacatures'},
			{title: 'menu.contact', link: '/contact'},
			showTranslationKeys && {
				title: 'menu.toggle_translations',
				onClick: handleToggleIgnoreTranslations,
			},
		],
	},
	{
		title: 'menu.pricing',
		link: '/prijzen',
	},
]
