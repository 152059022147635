import React, {forwardRef} from 'react'
import {Layout as LayoutComponent} from '@eitje/web_components'
import _ from 'lodash'
import {useWindowWidth} from '/src/contexts'
import {navigate} from 'gatsby'
import './index.less'

export const Layout = forwardRef(({extLink, to, onClick, width, height, maxWidth, gap, marginTop, margin, ...rest}, forwardRef) => {
	width = getResponsiveValue(width)
	gap = getResponsiveValue(gap)
	height = getResponsiveValue(height)
	margin = marginTop ? `${getResponsiveValue(marginTop)} - - -` : getResponsiveValue(margin)

	// Below props cannot be shared with web, since there are no modalLinks and we have a different router setup.
	let _onClick
	if (extLink) _onClick = () => window.open(extLink, '_blank')
	if (to)
		_onClick = () => {
			navigate(to)
			window.scrollTo(0, 0)
		}
	if (onClick) _onClick = onClick

	return (
		<LayoutComponent
			ref={forwardRef}
			onClick={_onClick}
			width={width}
			height={height}
			maxWidth={maxWidth}
			gap={gap}
			margin={margin}
			{...rest}
		/>
	)
})

const getResponsiveValue = value => {
	const {mobile, tablet} = useWindowWidth()

	if (_.isArray(value)) {
		if (mobile) {
			// Mobile: the first value in the array is always for mobile
			return value[0]
		} else if (tablet) {
			// Tablet: The second value of the array is always for tablet
			return value[1]
		} else {
			// Desktop: It will take the last value of the array (either 2 or 3)
			return value[value.length - 1]
		}
	}
	return value
}

export const getResp = getResponsiveValue
