import {ChatButton, PlanDemoButton, TryEitjeButton} from '../../eitje_button/buttons'
import {Layout, getResp} from '../../layout'
import {Text} from '../../text'
import {DelimitedList} from '../../delimited_list'
import {HORIZONTAL_BROWSER_MARGIN} from '.'
import {EitjeButton} from '../../eitje_button'
import {Link} from '../../link'
import {linkMapping} from '../../link/links'
import {getIsSupportOpen} from '/src/helpers'

export const TextColumn = ({mobilePreview, isHorizontal, paragraph, desktop, _title, _label, elements, asset, ...rest}) => {
	const ListComponent = desktop ? BulletList : List
	const columnWidth = ['100%', mobilePreview ? 300 : asset ? 360 : '100%', asset ? 360 : 300]

	return (
		<Layout
			className="text-column"
			width={columnWidth}
			direction="vertical"
			padding={[0, '0 0 0 16']}
			horizontal={isHorizontal ? 'start' : 'center'}
			gap={[12, isHorizontal ? 24 : 8, 12]}
		>
			<Layout direction="vertical" horizontal={isHorizontal ? 'start' : 'center'}>
				{isHorizontal && (
					<>
						{_label}
						{_title}
						{!!paragraph && <Text lineHeight={1.8} t={paragraph} />}
					</>
				)}
				{!!elements?.length && <ListComponent elements={elements} />}
			</Layout>
			<CTAbutton {...rest} />
			{asset && <Link {...linkMapping.call} />}
		</Layout>
	)
}

const CTAbutton = ({cta, buttonLink, buttonText}) => {
	const open = getIsSupportOpen()

	if (cta === 'no cta') return null
	if (buttonLink && buttonText) return <EitjeButton iconLeft="external-link" colorSet="solid" link={buttonLink} t={buttonText} />
	if (cta === 'try now') return <TryEitjeButton />
	if (cta === 'test us') return open ? <ChatButton t="common.test_us" /> : <TryEitjeButton />
	return <PlanDemoButton t={cta === 'plan appointment' ? 'common.plan_appointment' : 'common.plan_demo'} />
}

const List = ({elements}) => {
	if (!elements) return null
	const nonDesktopWidth = `calc(100% - ${HORIZONTAL_BROWSER_MARGIN * 2}px)`
	return <DelimitedList maxWidth={getResp(['100%', nonDesktopWidth, 660])} elements={elements} />
}

const BulletList = ({elements}) => (
	<ul>
		{elements?.map((el, i) => (
			<li key={i}>
				<Text t={el} />
			</li>
		))}
	</ul>
)
