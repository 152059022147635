import {Colors} from '@eitje/web_components'
import {Layout} from '../layout'
import {Icon} from '../icon'
import {Text} from '../text'

const inlineStyles = {breakInside: 'avoid'}

export const CheckedItem = ({t}) => (
	<Layout className="checked-item" gap={8} margin="- - 8 -" vertical="start" style={inlineStyles}>
		<Icon
			style={{marginTop: '3px'}} // Because we vertically align at the start for multiline text, we need to make sure the icon aligns well with the text
			type="bold"
			name="checked"
			fill={Colors.mediumGreen}
		/>
		<Text t={t} />
	</Layout>
)
