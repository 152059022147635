import React from 'react'
import {useWindowWidth} from '/src/contexts'
import {Blob} from '../blob'
import {Layout} from '../layout'
import {Phone} from '../phone'

export const PhoneRow = ({elements, ...rest}) => {
	const {mobile, tablet} = useWindowWidth()
	return (
		<Layout relative {...rest}>
			{!mobile && <Blob number={5} color="blue" top={100} height={tablet && '500px'} />}
			<Layout gap={[16, 32]} width="100%" childrenEqual horizontal="center" height={[140, 300, 372]}>
				{elements.map(Phone)}
			</Layout>
		</Layout>
	)
}
