import React, {useContext} from 'react'
import {useI18n} from '/src/i18n'

export const I18nContext = React.createContext()

export const I18nProviderWrapper = ({children}) => {
	const [t, intl] = useI18n()
	return <I18nContext.Provider value={{t, intl}}>{children}</I18nContext.Provider>
}

export const useI18nContext = () => {
	const context = useContext(I18nContext)
	return context
}
