import {useIntersectionObserver, usePrevious} from '@eitje/react-hooks'
import {useEffect, useState} from 'react'

const observerOptions = {
	root: null, // observing relative to the viewport
	threshold: 0.1, // callback is invoked when 10% of the target is visible
}

export const useWasInViewport = observedElementRef => {
	const [wasInViewport, setWasInViewport] = useState(false)
	const [isVisible, setIsVisible] = useState(false)
	const previousVisible = usePrevious(isVisible)

	useEffect(() => {
		// As soon as the element has been visible in the viewport once,
		// we mark it has wasInViewport when it has left the viewport
		if (!isVisible && previousVisible) setWasInViewport(true)
	}, [isVisible, previousVisible])

	const handleVisibilityChange = entry => {
		setIsVisible(entry.isIntersecting)
	}

	useIntersectionObserver(observedElementRef, handleVisibilityChange, observerOptions)

	return wasInViewport
}
