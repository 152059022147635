import {Colors} from '@eitje/web_components'
import {Image} from '../image'
import {Layout} from '../layout'
import {Text, Title} from '../text'
import {UserInfo} from '../user_info'
import {useWindowWidth} from '/src/contexts'
import {Link} from '../link'
import './styles.less'

export const PARENT_GAP = 56

export const BlogArticleCard = ({title, featured, category, author, slug, excerpt, image, imageWidth = '100%', contentWidth = '100%'}) => {
	const {name, avatar} = author
	const {mobile} = useWindowWidth()

	return (
		<Link to={`/blog/${slug}`} displayContents>
			<Layout
				className="blog-article-card"
				parentGap={PARENT_GAP}
				direction={featured & !mobile ? 'horizontal' : 'vertical'}
				width={featured ? '100%' : undefined}
				vertical="start"
				gap={featured && !mobile ? 0 : 12}
			>
				<Image
					// updating the width prop does not resize the image, so we use the key to force a rerender
					key={imageWidth}
					image={image}
					width={imageWidth}
					height={featured && !mobile ? 374 : 192}
					border={`1px solid ${Colors.mediumBorder}`}
					borderRadius={featured ? 8 : 4}
				/>
				<Layout direction="vertical" width={contentWidth} padding={featured && !mobile && '- - - 56'} gap={[16, 20]}>
					<Layout direction="vertical" gap={8}>
						{featured ? (
							<Title className="blog-article-title" small t={title} />
						) : (
							<Text className="blog-article-title" large bold t={title} />
						)}
						<Text darkGrey t={excerpt} />
					</Layout>
					<UserInfo name={name} size="small" nameColor="black" image={avatar} subtext={category.title} />
				</Layout>
			</Layout>
		</Link>
	)
}
