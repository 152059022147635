import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {Banner, Layout, PageLayout, PerksHighlight, Title, Youtube} from '/src/components'
import {PartnerLogos} from '/src/components/partner_logos'

const InnerPage = ({data}) => {
	const {partner} = data
	return (
		<>
			<Banner type="become_partner_process" marginTop={60} gap={32}>
				<PerksHighlight highlights={['first_contact', 'online_meeting', 'building']} />
			</Banner>
			<PartnerLogos partners={partner} marginTop={100} />
			<Layout direction="vertical" horizontal="center" gap={32} marginTop={100}>
				<Title t="common.why_collaborate" />
				<PerksHighlight highlights={['technical_knowledge', 'great_support', 'marketing']} />
			</Layout>
			<Banner marginTop={100} type="eitje_in_short">
				<Youtube video="eitje-intro" />
			</Banner>
			<Banner type="contact_no_video_button" margin="100 -" />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})

	return (
		<PageLayout name="partner-worden" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "partner-worden"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}
		allContentfulPartner(sort: {orderSelection: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					orderSelection
					logoBlock {
						gatsbyImageData
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
