import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {ProductPageLayout, ProductHighlights, ProductTiles, Quotes} from '/src/components'

const PRODUCTS = [
	['exports_design_your_own_excels', 'exports_customise_filters_and_layout'],
	['exports_ready_made_excels', 'exports_subscriptions'],
]

const SCROLL_LINKS = [
	'exports_design_your_own_excels',
	'exports_customise_filters_and_layout',
	'exports_ready_made_excels',
	'exports_subscriptions',
]

const PageChildren = ({data}) => {
	const {products, tiles, quotes} = formatData({data, log: true})

	return (
		<>
			<ProductHighlights highlights={products} items={PRODUCTS[0]} />
			<Quotes items={quotes[0]} />
			<ProductHighlights highlights={products} items={PRODUCTS[1]} />
			<ProductTiles type="which_data_do_you_want_to_export" items={tiles} />
		</>
	)
}

const Page = ({data}) => {
	const {seo, faq} = formatData({data})

	return (
		<ProductPageLayout name="exports" blobColor="green" {...seo} faq={faq} scrollLinks={SCROLL_LINKS}>
			<PageChildren data={data} />
		</ProductPageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					highlights
					shortHighlights
					cta
					name
					asset {
						gatsbyImageData
					}
					preview {
						gatsbyImageData
					}
					previews {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					label
					buttonLink
					buttonText
					paragraph {
						paragraph
					}
				}
			}
		}
		contentfulPage(slug: {eq: "exports"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
			productTiles {
				title
				subtitle
				icon
			}
			featureList
			quotes {
				name
				jobTitle
				image {
					gatsbyImageData
				}
				quote {
					quote
				}
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
