export const insertInRichText = (raw, inserts, insertBefore = 'heading-3') => {
	let object = JSON.parse(raw)
	const contentArray = object.content

	let headingCount = 0
	let insertPositions = {}

	// Determine the insert positions based on the specified indexes
	inserts.forEach(insert => {
		insertPositions[insert.index] = {
			nodeType: insert.component,
			data: {},
			content: [],
		}
	})

	// Iterate over the content array to find heading-3 occurrences and adjust insert positions
	for (let i = 0; i < contentArray.length; i++) {
		if (contentArray[i].nodeType === insertBefore) {
			headingCount++
			// If an insert position matches the current heading count, adjust the position to the current index
			if (insertPositions[headingCount]) {
				// Insert component at the determined position
				contentArray.splice(i, 0, insertPositions[headingCount])
				i++ // Adjust loop counter to account for the newly inserted element
			}
		}
	}

	object.content = contentArray
	return JSON.stringify(object)
}
