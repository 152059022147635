import React, {Fragment} from 'react'
import {Layout} from '../layout'
import {Text} from '../text'
import _ from 'lodash'
import {useI18n} from '/src/i18n'
import {showSupportChat} from '/src/helpers'
import './styles.less'

export const DelimitedList = ({delimiter = ' ・ ', elements, wrap = true, Component = Fragment, componentProps, textProps, ...rest}) => {
	const inlineStyles = {flexWrap: wrap && 'wrap'}
	return (
		<Layout className="delimited-list" horizontal="center" vertical="center" style={inlineStyles} {...rest}>
			{elements.filter(Boolean).map((el, i) => (
				<Text darkGrey {...textProps}>
					{i > 0 && delimiter}
					<Content index={i} Component={Component} element={el} {...componentProps} />
				</Text>
			))}
		</Layout>
	)
}

const Content = ({Component, element, onClick, index}) => {
	const [t] = useI18n()

	let condOpts = {}
	if (onClick) {
		condOpts.onClick = () => onClick(element, index)
	}

	if (element.text === 'links.chat') {
		condOpts.onClick = () => showSupportChat(' ')
		to: undefined
	}

	if (Component)
		return (
			<Component {...element} {...condOpts}>
				{!_.isObject(element) && element}
			</Component>
		)
	return t(element)
}
