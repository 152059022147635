import React from 'react'
import {Layout, getResp} from '../layout'
import {Image} from '../image'

const gap = [8, 24, 40]

export const Logos = ({logos = [], ...rest}) => {
	const amountPerLine = getResp([4, 5])
	const logoGap = getResp(gap)
	const logoWidth = `calc(${100 / amountPerLine}% - ${logoGap}px)`

	const inlineStylesContainer = {flexWrap: 'wrap'}

	const logoElements = logos.map((logo, i) => (
		<Layout key={i} width={logoWidth} horizontal="center">
			<Image width="100%" alt="logo" image={logo} />
		</Layout>
	))
	return (
		<Layout horizontal="center" style={inlineStylesContainer} gap={gap} {...rest}>
			{logoElements}
		</Layout>
	)
}
