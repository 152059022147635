import {useIntl} from 'gatsby-plugin-react-intl'
import {isIgnored} from './actions/ignore_trans'
import {isProduction} from './constants'

export const useI18n = () => {
	const ignored = isIgnored()
	const intl = useIntl()
	return [
		(text, opts) => {
			if (!text) return
			if (typeof localStorage !== 'undefined' && !isProduction && localStorage?.getItem('ignoreTranslations')) return text
			return ignored ? text : intl.formatMessage({id: text}, opts)
		},
		intl,
	]
}

export const useT = () => {
	const [t, intl] = useI18n()
	const lng = intl.locale || 'nl'
	return {t, intl, lng}
}

export const useLocale = () => {
	const [t, intl] = useI18n()
	return intl.locale
}

export default useI18n
