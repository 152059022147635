import React from 'react'
import _ from 'lodash'

export const flattenGraphQLResponse = data => {
	if (_.isArray(data)) {
		return data.map(flattenGraphQLResponse)
	} else if (_.isObject(data)) {
		if (data.hasOwnProperty('edges')) {
			// Directly return the flattened array of nodes
			return _.flatMap(data.edges, edge => flattenGraphQLResponse(edge.node))
		} else {
			return _.reduce(
				data,
				(newData, value, key) => {
					if (_.isObject(value) && value.hasOwnProperty(key)) {
						// When the nested key is the same as the current key, remove nesting (e.g. markdown respone)
						newData[key] = value[key]
					}
					// Check for the specific pattern { [key]: { raw: 'test' } } and adjust
					else if (_.isObject(value) && value.hasOwnProperty('raw') && _.size(value) === 1) {
						// Directly use the raw value
						newData[key] = value.raw
					} else {
						// Strip all Contentful related prefixes and ensure each key still starts with a lowercase
						key = key.replace(/^(all)?Contentful/i, '')
						key = key.charAt(0).toLowerCase() + key.slice(1)

						newData[key] = flattenGraphQLResponse(value)
					}
					return newData
				},
				{},
			)
		}
	}
	return data
}

// Higher-Order Component that uses flattenGraphQLResponse
export const withFlattenedResponse = WrappedComponent => props => {
	const flattenedProps = _.mapValues(props, value => {
		if (_.isObject(value)) {
			return flattenGraphQLResponse(value)
		}
		return value
	})

	return <WrappedComponent {...flattenedProps} />
}
