import {sectorSlugMapping} from '../../constants'
import {blobColors} from '../blob/constants'

export const getHighlight = ({highlight, highlights, previews, sectorName}) => {
	const item = highlights.find(x => x.uuid === highlight)
	if (item) {
		const _sectorName = sectorSlugMapping[sectorName] || sectorName
		const currentImageTitle = `${highlight}-${_sectorName}`
		const preview = item.preview || previews?.find(m => m.title === currentImageTitle) // item.preview will later be renamed in contenful to previews plural
		if (preview) {
			item.image = preview.gatsbyImageData
			item.imageTitle = preview.title
		}
	}
	return item
}

const SUITABLE_BLOBS = 4 // Blob 1 to 4 are suitable for usage in this component so we cycle through them

export const mapHighlights = ({items, highlights, previews, sectorName, coloringOffsetAmount = 0, ...rest}) => {
	// The coloring offset determins from what index of the color array the blobs are colored
	// The have a consistent order of colors, even when other components are in between the highlights
	// We pass this offset. It also takes care of the mirroring of preview vs textcolumn and the blob shape order
	return items.map((h, i) => {
		const correctedBlobNumber = coloringOffsetAmount + i
		const highlight = getHighlight({
			highlight: h,
			highlights,
			previews,
			sectorName,
		})

		const blobIndex = correctedBlobNumber % SUITABLE_BLOBS
		const blobColor = blobColors[correctedBlobNumber % blobColors.length]

		return {
			index: i,
			blobNumber: blobIndex + 1, // maps to files, not 0-indexed
			blobColor,
			horizontalInversed: correctedBlobNumber % 2 !== 0,
			...rest,
			...highlight,
		}
	})
}
