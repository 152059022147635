import {Colors} from '@eitje/web_components'
import {Layout} from '../layout'
import {Text} from '../text'
import {useWindowWidth} from '/src/contexts'
import {TryEitjeButton} from '../eitje_button/buttons'
import {Link} from '../link'
import {useGetCountry} from '../../hooks/use_get_country'
import {createAccountUrl} from '../../constants'
import _ from 'lodash'

const inlineStyles = {
	background: Colors.mediumBlue,
}

export const BelgiumHeader = () => {
	const {desktop} = useWindowWidth()
	const country = useGetCountry()
	const userInBelgium = country === 'Belgium'

	if (!userInBelgium) return null
	return (
		<Link to={createAccountUrl} displayContents>
			<Layout style={inlineStyles} padding={8} width="100%" horizontal="center">
				{desktop ? <DekstopHeader /> : <MobileHeader />}
			</Layout>
		</Link>
	)
}

const DekstopHeader = () => (
	<Layout gap={12}>
		<Text semiBold white t="header.belgium.desktop" />
		<TryEitjeButton colorSet height="small" t="links.try_3_months" />
	</Layout>
)

const MobileHeader = () => (
	<>
		🇧🇪 <Text white semiBold underline t="header.belgium.three_months_free" />{' '}
		<Text white semiBold lowercase t="header.belgium.mobile_text" />
	</>
)
