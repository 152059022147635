import {useWindowWidth} from '/src/contexts'
import {useI18n} from '/src/i18n'
import {Image} from '../image'
import {Layout} from '../layout'
import {Text, Title} from '../text'
import {makeQuote} from '/src/components'

export const Testimonials = ({items, ...rest}) => {
	const {mobile} = useWindowWidth()
	return (
		<Layout vertical="start" className="testimonials" horizontal="center" childrenPerRow={mobile ? 1 : 3} gap={40}>
			{items?.map(i => (
				<Testimonial namespace={i.key} {...rest} {...i} />
			))}
		</Layout>
	)
}

const defaultImgStyle = {
	objectFit: 'cover',
	objectPosition: 'top',
}

export const Testimonial = props => {
	const {namespace, image, imgStyle = defaultImgStyle} = props
	const {mobile} = useWindowWidth()
	const [t] = useI18n()
	const testimonialKey = `testimonials.${namespace}`
	const text = makeQuote({t: `${testimonialKey}.text`})

	return (
		<Layout className="testimonial" direction="vertical" parentGap={40} gap={16} maxWidth={mobile && 280}>
			<Image image={image} height={220} width="100%" borderRadius={4} imgStyle={imgStyle} />
			<Layout direction="vertical" horizontal="start" width="100%">
				<Title small t={`${testimonialKey}.title`} />
				<Text fontSize={mobile ? 16 : 22} darkGrey t={`${testimonialKey}.subtitle`} />
			</Layout>
			<Text>{text}</Text>
		</Layout>
	)
}
