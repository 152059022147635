import React from 'react'
import utils from '@eitje/utils'
import {useBoolState} from '@eitje/react-hooks'
import {Layout} from '../layout'
import {Text, Title} from '../text'
import {linkMapping} from '../link/links'
import {Link} from '../link'
import {RichText} from '../rich_text'
import {Icon} from '../icon'
import './styles.less'
import {TEXT_PAGE_WIDTH} from '../../constants'
import {Colors} from '@eitje/web_components'
import {showSupportChat} from '/src/helpers'

export const FAQ = ({items, ...rest}) => {
	return (
		<Layout direction="vertical" width="100%" maxWidth={TEXT_PAGE_WIDTH} horizontal="center" gap={[16, 24]} {...rest}>
			<Title t="faq" />
			<Layout borderTop width="100%" direction="vertical" gap={0}>
				{items.map((item, i) => (
					<Question key={i} item={item} {...rest} />
				))}
			</Layout>
			<Layout width="100%" marginTop={[undefined, -8]}>
				<Link {...linkMapping.chat_questions} onClick={showSupportChat} />
			</Layout>
		</Layout>
	)
}

const options = {
	paragraph: (_, children) => <Text darkGrey>{children}</Text>,
}

const Question = ({item, openByDefault = false}) => {
	const [open, toggleOpen] = useBoolState(openByDefault)
	const classNames = utils.makeCnVariants('plus-icon', open && 'open')
	return (
		<Layout
			className="faq-question"
			borderBottom
			width="100%"
			padding="16 4"
			onClick={toggleOpen}
			direction="vertical"
			gap={16}
			data-heap-id={item.question.slice(0, 15)} // Do not use full string to prevent cluttering the DOM
		>
			<Layout horizontal="spaceBetween">
				<Text>{item.question}</Text>
				<Icon className={classNames} name="plus" />
			</Layout>
			{open && <RichText alignSelf="stretch" options={options} fullWidth content={item.answer} />}
		</Layout>
	)
}
