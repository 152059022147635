import React, {useContext} from 'react'

const SimpleContext = React.createContext({})
const {Provider} = SimpleContext

export const SimpleContextWrapper = ({children, ...rest} = {}) => {
	return <Provider value={rest}>{children}</Provider>
}

export const useSimpleContext = () => {
	const context = useContext(SimpleContext)
	return context
}

export default SimpleContextWrapper
