import React from 'react'
import {EitjeButton} from '.'
import {showSupportChat} from '/src/helpers'
import {Link} from '../link'
import {apiDocsUrl, calendlyUrl, salesPhoneNumber, webAppUrl, createAccountUrl} from '/src/constants'
import {useI18n} from '/src/i18n'
import {navigate} from 'gatsby'
import {useLocaleUrl} from '/src/hooks'

export const TryEitjeButton = props => {
	return (
		<EitjeButton
			className="try-eitje-button"
			colorSet="solid"
			t={props.shortText ? 'common.try_free' : 'common.try_eitje_free'}
			link={createAccountUrl}
			{...props}
		/>
	)
}

export const Try28DaysButton = props => {
	return <TryEitjeButton t="common.try_free_28_days" />
}

export const PlanDemoButton = props => {
	return <EitjeButton className="plan-demo-button" colorSet="solid" t="common.plan_demo" link={calendlyUrl} target="_blank" {...props} />
}

export const ChatButton = props => {
	return (
		<EitjeButton
			className="chat-button"
			colorSet="solid"
			iconLeft="chat"
			t="common.chat"
			onClick={showSupportChat}
			data-heap-id="support-chat"
			{...props}
		/>
	)
}

export const CallButton = props => {
	return <EitjeButton className="call-button" iconLeft="phone" t="common.call" link={`tel:${salesPhoneNumber}`} {...props} />
}

export const MailButton = props => {
	return <EitjeButton className="mail-button" iconLeft="envelope" t="common.mail" link="mailto:help@eitje.app" {...props} />
}

export const LinkButton = ({link, style, debug, ...rest}) => {
	const {navigateUrl, target} = useLocaleUrl(link)
	return <EitjeButton className="link-button" {...rest} onClick={navigateUrl} />
}

export const IntegrationChatButton = props => {
	const [t] = useI18n()
	return (
		<EitjeButton
			colorSet="solid"
			className="integration-chat-button"
			t="common.ask_integrations"
			onClick={() => showSupportChat(t('intercom.integrations_text'))}
			data-heap-id="support-chat-integrations"
			{...props}
		/>
	)
}

export const IntegrationIndexButton = props => {
	return <EitjeButton className="integration-index-button" link="/partners" t="menu.see_all_integrations" {...props} />
}

export const VideoButton = ({videoId, ...rest}) => <EitjeButton iconLeft="video" t="common.watch_video" {...rest} link="/video" />

export const ApiButton = props => {
	return <EitjeButton className="api-docs-button" colorSet="solid" t="links.see_api_docs" link={apiDocsUrl} {...props} />
}

export const BecomePartnerButton = props => {
	return <EitjeButton className="become-partner-button" colorSet="solid" t="links.become_partner" link="/partner-worden" {...props} />
}

export const TeamButton = props => {
	return <EitjeButton className="team-button" iconLeft="heart" t="common.the_team" link="/team" {...props} />
}

export const LoginButton = props => {
	return <EitjeButton className="login-button" iconLeft="unlocked" t="login" link="https://web.eitje.app/login" {...props} />
}
