import {Image} from '../image'
import {Layout} from '../layout'
import {Text} from '../text'
import {Link} from '../link'
import {DelimitedList} from '../delimited_list'
import './styles.less'

export const ProfileCard = ({item}) => {
	const {profilePicture, name, linkedin, mail} = item
	const links = [
		{text: 'mail', to: `mailto:${mail}`},
		linkedin && {
			text: 'linkedin',
			to: `https://www.linkedin.com/in/${linkedin}`,
		},
	]
	return (
		<Layout className="profile-card" direction="vertical" horizontal="center">
			<Image className="profile-image" image={profilePicture} />
			<Text large bold center>
				{name}
			</Text>
			<DelimitedList wrap={false} elements={links} Component={Link} />
		</Layout>
	)
}
