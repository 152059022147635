import {Banner, Layout, Text, Title} from '../components'

const inlineStylesParagraph = {textAlign: 'justify', marginTop: 8}
const inlineStylesHeading3 = {marginTop: 40, alignSelf: 'center'}
const inlineStylesWrapper = {alignSelf: 'center'}

const Wrapper = ({children}) => (
	<Layout width={600} maxWidth="100%" padding="- 8" direction="vertical" style={inlineStylesWrapper}>
		{children}
	</Layout>
)

export const options = {
	paragraph: (_, children) => (
		<Wrapper>
			<Text lineHeight={2.5} large styles={inlineStylesParagraph}>
				{children}
			</Text>
		</Wrapper>
	),
	'heading-3': (_, children) => (
		<Wrapper>
			<Title center styles={inlineStylesHeading3}>
				{children}
			</Title>
		</Wrapper>
	),
	online_administration: () => <Banner type="online_administration" marginTop={40} />,
	try_for_free: () => <Banner type="try_for_free" marginTop={40} />,
	'unordered-list': (_, children) => (
		<Wrapper>
			<ul>{children}</ul>
		</Wrapper>
	),
	'ordered-list': (_, children) => (
		<Wrapper>
			<ol>{children}</ol>
		</Wrapper>
	),
}

export const insertArray = [
	{index: 3, component: 'online_administration'},
	{index: 6, component: 'try_for_free'},
]
