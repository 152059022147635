import {Layout} from '../layout'
import './styles.less'

const inlineStyles = {overflow: 'hidden'}

export const ViewportWidthBackground = ({children, height, background, ...rest}) => {
	return (
		<Layout className="viewport-width-background-container" style={{background}} height={height} gap={0} horizontal="center">
			<Layout style={{background}} width="100vw" height={height} className="viewport-width-background" />
			<Layout width="100%" relative style={inlineStyles} height={height} vertical="center" horizontal="center" {...rest}>
				{children}
			</Layout>
		</Layout>
	)
}
