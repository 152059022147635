import {Icon, Colors} from '@eitje/web_components'
import {useI18n} from '/src/i18n'
import {useWindowWidth} from '/src/contexts'
import utils from '@eitje/utils'
import {Banner, Blob, Youtube, Layout, DelimitedList} from '/src/components'
import {bannerMapping} from './mapping'
import {requireProps} from '/src/helpers'
import './styles.less'

const textProps = {large: true, weight: 'semiBold', color: Colors.black}

const delimiter = <Icon name="arrow-small-right" size={18} />

export const VideoBanner = ({type, blobNumber = 2, blobColor, autoplay = true, layoutProps, ...rest}) => {
	requireProps('VideoBanner', {type})
	let {steps} = bannerMapping[type]
	const [t] = useI18n()
	const {desktop} = useWindowWidth()
	steps = steps.map(s => utils.capitalize(t(`video_banner.steps.${s}`)))

	return (
		<Layout className="video-banner" direction="vertical" width="100%" horizontal="center" gap={32} {...layoutProps}>
			<Banner type={type} {...rest} />
			<Layout relative maxWidth={800} horizontal="center" direction="vertical" gap={8} width="100%">
				{desktop && <Blob number={blobNumber} color={blobColor} position="left" top={110} />}
				<DelimitedList elements={steps} delimiter={delimiter} textProps={textProps} />
				<Youtube video={type} autoplay={autoplay} />
			</Layout>
		</Layout>
	)
}
