import {Link, Text} from '../components'

export const parseAndRenderLinks = (text, linkProps) => {
	const linkPattern = /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g
	const parts = []
	let lastIndex = 0

	text.replace(linkPattern, (match, linkText, href, index) => {
		// Text before link
		if (index > lastIndex) {
			parts.push(text.substring(lastIndex, index))
		}
		// Link
		parts.push(
			<Link to={href} key={href}>
				<Text className="inline-link-text" {...linkProps}>
					{linkText}
				</Text>
			</Link>,
		)
		lastIndex = index + match.length
	})

	// Remaining text after the last link
	if (lastIndex < text.length) {
		parts.push(text.substring(lastIndex))
	}

	return parts
}
