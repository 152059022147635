import _ from 'lodash'

export const formatCurrency = number => {
	const parsedNumber = parseFloat(number)
	number = _.isNaN(parsedNumber) ? 0 : parsedNumber
	return Intl.NumberFormat('nl-NL', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 2,
	}).format(number)
}
