import {Paragraph} from '.'
import {Layout} from '../layout'

export const ParagraphList = ({items, type, ...rest}) => (
	<Layout direction="vertical" gap={[40, 60]} {...rest}>
		{items.map((block, i) => {
			let title = block.title
			if (type === 'orderedList') title = `${i + 1}. ${block.title}`
			return <Paragraph {...block} title={title} />
		})}
	</Layout>
)
