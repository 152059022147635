import {
	cocNumber,
	eitjeMail,
	eitjePhoneNumber,
	eitjePhoneNumberFormatted,
	googleMapsUrl,
	instagramUrl,
	linkedInUrl,
	officeStreet,
	officeZipCode,
	salesPhoneNumber,
	salesPhoneNumberFormatted,
	vatNumber,
	youtubeUrl,
	tikTokUrl,
	facebookUrl,
} from '../../constants'
import {useWindowWidth} from '/src/contexts'
import {useI18n} from '/src/i18n'
import {Layout} from '../layout'
import {Link} from '../link'
import {Text} from '../text'

export const ContactInformation = props => {
	const {desktop} = useWindowWidth()
	const [t] = useI18n()

	return (
		<Layout
			width="100%"
			direction={desktop ? 'horizontal' : 'vertical'}
			gap={desktop ? 0 : 28}
			horizontal={desktop ? 'spaceBetween' : 'center'}
			vertical="start"
			marginTop={24}
			{...props}
		>
			<Column title="common.contact">
				<ContentRow>
					<Link to={`tel:${eitjePhoneNumber}`}>{eitjePhoneNumberFormatted}</Link>
					<Text lowercase>({t('common.general')})</Text>
				</ContentRow>
				<ContentRow>
					<Link to={`tel:${salesPhoneNumber}`}>{salesPhoneNumberFormatted}</Link>
					<Text lowercase>{`(${t('common.sales')})`}</Text>
				</ContentRow>
				<Link to={`mailto:${eitjeMail}`}>{eitjeMail}</Link>
			</Column>
			<Column title="common.address">
				<Text t={officeStreet} />
				<Text t={officeZipCode} />
				<Link to={googleMapsUrl}>Google Maps</Link>
			</Column>
			<Column title="common.information">
				<ContentRow>
					<Text t={cocNumber} />
					<Text>({t('common.coc')})</Text>
				</ContentRow>
				<ContentRow>
					<Text t={vatNumber} />
					<Text uppercase>({t('common.vat')})</Text>
				</ContentRow>
			</Column>
			<Column title="common.media">
				<Link to={youtubeUrl}>YouTube</Link>
				<Link to={linkedInUrl}>LinkedIn</Link>
				<Link to={tikTokUrl}>TikTok</Link>
				<Link to={instagramUrl}>Instagram</Link>
				<Link to={facebookUrl}>Facebook</Link>
			</Column>
		</Layout>
	)
}

const Column = ({children, title, ...rest}) => {
	const {desktop} = useWindowWidth()
	const horizontal = desktop ? 'start' : 'center'
	return (
		<Layout direction="vertical" horizontal={horizontal} gap={8} {...rest}>
			<Text bold uppercase t={title} />
			{children}
		</Layout>
	)
}

const ContentRow = props => {
	return <Layout vertical="center" {...props} />
}
