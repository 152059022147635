import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {PageLayout, ContactInformation, Image} from '/src/components'

const InnerPage = ({data}) => {
	const {asset} = formatData({data})
	const teamPhoto = asset.find(a => a.title === 'team-photo')

	return (
		<>
			<Image width="100%" className="image-full-width" style={{marginTop: 28}} image={teamPhoto} />
			<ContactInformation />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})
	return (
		<PageLayout name="contact" banner="contact_page" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "contact"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		allContentfulAsset(filter: {node_locale: {eq: $locale}, title: {in: ["team-photo"]}}) {
			edges {
				node {
					title
					gatsbyImageData
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
