import {Layout} from '../../layout'
import {RichText} from '../../rich_text'
import {Text} from '../../text'
import {Preview} from './preview'
import {TextColumn} from './text_column'

export const ProductHighlightChildren = props => {
	const text = <TextColumn {...props} />
	const preview = <Preview {...props} />

	const children = [text, preview]
	if (props.isInversed) children.reverse()

	return children
}

export const Label = ({color, label, buttonText}) => {
	const inlineStyles = {backgroundColor: color}
	return (
		<Layout gap={6}>
			<Layout height={20} borderRadius={1} width={6} style={inlineStyles} />
			<Text darkGrey bold uppercase t={label || buttonText} />
		</Layout>
	)
}

const options = {
	paragraph: (_, children) => (
		<Text darkGrey fontSize={14} center>
			{children}
		</Text>
	),
}

export const TextBlock = ({title, text, ...rest}) => (
	<Layout direction="vertical" parentGap={24} horizontal="center" {...rest}>
		<Text bold center t={title} fontWeight={600} />
		<RichText content={text} options={options} />
	</Layout>
)

export const HORIZONTAL_BROWSER_MARGIN = 64
