import * as React from 'react'

export class ErrorBoundary extends React.Component {
	state = {error: null}

	componentDidCatch(error, errorInfo) {
		this.setState({error})
		console.error('Error caught by ErrorBoundary:', error, errorInfo)
	}

	render() {
		// Without this check => possible infinite loop
		return this.state.error && this.props.hasErrors ? <h1> Oops... Something went wrong </h1> : this.props.children
	}
}
