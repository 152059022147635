import {Icon} from '@eitje/web_components'
import {Layout} from '../layout'
import {MenuLink} from './components'
import {useT} from '../../i18n'
import {TryEitjeButton} from '../eitje_button/buttons'
import {calendlyUrl} from '../../constants'
import {useWindowWidth} from '/src/contexts'
import {handleChangeLanguage} from './helpers'
import {Text} from '../text'
import {isPathIgnored} from '../../helpers/change_language'
import utils from '@eitje/utils'

export const RightMenu = ({mobileMenuOpen, setMobileMenuOpen}) => {
	const {desktop} = useWindowWidth()
	const {lng} = useT()
	const isIgnored = isPathIgnored()

	const languageClassNames = utils.makeCnVariants('menu-language', !isIgnored && 'active')
	let condOpts = {}
	if (!isIgnored) {
		condOpts.onClick = () => handleChangeLanguage(lng)
	}

	if (desktop)
		return (
			<Layout gap={28}>
				<MenuLink link={calendlyUrl} title="links.book_demo" />
				<MenuLink link="https://web.eitje.app/login" title="login" />
				<Layout {...condOpts} className={languageClassNames}>
					<Icon name="world" />
					<Text black fontFamily="system-ui">
						{lng.toUpperCase()}
					</Text>
				</Layout>
				<TryEitjeButton height="regular" />
			</Layout>
		)

	return (
		<Layout gap={16} margin="- 24 - -">
			{!mobileMenuOpen && <TryEitjeButton height="small" shortText />}
			<Icon onClick={() => setMobileMenuOpen(!mobileMenuOpen)} type="bold" size={20} name={mobileMenuOpen ? 'cross' : 'three-lines'} />
		</Layout>
	)
}
