import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {ProductPageLayout, Quotes, ClientLogosBanner, ProductHighlights} from '/src/components'

const PRODUCTS = [
	['mobile_shift_overview', 'mobile_trade_shifts'],
	['mobile_availability', 'mobile_write_hours'],
	['mobile_ask_leave', 'mobile_articles_and_training', 'mobile_in_touch_with_your_team'],
]

const SCROLL_LINKS = ['mobile_shift_overview', 'mobile_trade_shifts', 'mobile_availability', 'mobile_write_hours']

const PageChildren = ({data}) => {
	const {products, clientLogos, faq, quotes} = formatData({data})

	return (
		<>
			<ProductHighlights highlights={products} items={PRODUCTS[0]} coloringOffsetAmount={2} />
			<Quotes items={quotes[0]} />
			<ProductHighlights highlights={products} items={PRODUCTS[1]} />
			<ClientLogosBanner logos={clientLogos} />
			<ProductHighlights highlights={products} items={PRODUCTS[2]} coloringOffsetAmount={4} />
			<Quotes items={quotes[1]} />
		</>
	)
}

const Page = ({data}) => {
	const {seo, faq} = formatData({data})
	return (
		<ProductPageLayout name="app" video={false} {...seo} faq={faq} scrollLinks={SCROLL_LINKS}>
			<PageChildren data={data} />
		</ProductPageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					highlights
					shortHighlights
					cta
					name
					asset {
						gatsbyImageData
					}
					preview {
						gatsbyImageData
					}
					previews {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					label
					buttonLink
					buttonText
					paragraph {
						paragraph
					}
				}
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
		allContentfulClient(sort: {order: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logo {
						gatsbyImageData
					}
				}
			}
		}
		contentfulPage(slug: {eq: "mobile-app"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
			quotes {
				name
				jobTitle
				image {
					gatsbyImageData
				}
				quote {
					quote
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
