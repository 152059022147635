import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {
	Banner,
	SupportBanner,
	ProductPageLayout,
	ProductTiles,
	CheckedItemList,
	ProductHighlights,
	ClientLogosBanner,
	PartnerLogosBanner,
	PerksBanner,
	Quotes,
} from '/src/components'

const PERKS = [
	[
		{type: 'monthly', hide_cta: true},
		{type: 'lowest_price', hide_cta: true},
		{type: 'integrations', hide_cta: true},
	],
	[
		{type: 'new_business', hide_cta: true},
		{type: 'one_location', hide_cta: true},
		{type: 'multiple_locations', hide_cta: true},
	],
]

const PRODUCTS = [
	['connect_accounting_software'],
	['clock_system'],
	['automatic_balance', 'hours_in_app'],
	['analyse_hours'],
	['hours_roles'],
]

const PageChildren = ({data}) => {
	const {clientLogos, partnerLogos, products, tiles, quotes, minorFeatures} = formatData({data})

	return (
		<>
			<PerksBanner type="no_hidden_costs" highlights={PERKS[0]} marginTop={0} />
			<ProductHighlights highlights={products} items={PRODUCTS[0]} />
			<Quotes items={quotes[0]} />
			<ProductHighlights highlights={products} items={PRODUCTS[1]} coloringOffsetAmount={1} />
			<PartnerLogosBanner logos={partnerLogos} />
			<ProductHighlights highlights={products} items={PRODUCTS[2]} coloringOffsetAmount={2} />
			<SupportBanner />
			<ProductHighlights highlights={products} items={PRODUCTS[3]} coloringOffsetAmount={4} />
			<Quotes items={quotes[1]} />
			<ProductHighlights highlights={products} items={PRODUCTS[4]} coloringOffsetAmount={5} />
			<ClientLogosBanner logos={clientLogos} />
			<ProductTiles type="hours_features" items={tiles} />
			<CheckedItemList type="other_hours_features" items={minorFeatures} columnCount={2} />
			<PerksBanner type="every_company_size" highlights={PERKS[1]} />
		</>
	)
}

const Page = ({data}) => {
	const {seo, faq} = formatData({data})

	return (
		<ProductPageLayout name="hours" blobColor="green" {...seo} faq={faq}>
			<PageChildren data={data} />
		</ProductPageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					highlights
					shortHighlights
					cta
					name
					preview {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					paragraph {
						paragraph
					}
				}
			}
		}
		allContentfulPartner(sort: {orderSelection: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logoBlock {
						gatsbyImageData
					}
				}
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
		allContentfulClient(sort: {order: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logo {
						gatsbyImageData
					}
				}
			}
		}
		contentfulPage(slug: {eq: "hours"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription

			productTiles {
				title
				subtitle
				icon
			}
			featureList
			quotes {
				name
				jobTitle
				image {
					gatsbyImageData
				}
				quote {
					quote
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
