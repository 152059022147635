export const getIsSupportOpen = () => {
	const now = new Date()
	const amsterdamTime = now.toLocaleString('en-US', {
		timeZone: 'Europe/Amsterdam',
	})

	const amsterdamDate = new Date(amsterdamTime)
	const minutesElapsedToday = amsterdamDate.getHours() * 60 + amsterdamDate.getMinutes()

	return (
		// open between 570 (9:30) and 750 minutes (12:30) and between 780 (13:00) and 1020 (17:00)
		(minutesElapsedToday >= 570 && minutesElapsedToday <= 750) || (minutesElapsedToday >= 780 && minutesElapsedToday <= 1020)
	)
}
