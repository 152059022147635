import {ProductHighlight} from '.'

import {Carousel} from '../carousel'
import {mapHighlights} from './helpers'

export const ProductHighlightCarousel = props => {
	const elements = mapHighlights({
		...props,
		showTitleOnMobile: false,
	})

	return <Carousel marginTop={[80, 80, 180]} title="common.much_more" Component={ProductHighlight} elements={elements} />
}
