import {Colors} from '@eitje/web_components'
import {useMenuMapping} from './hooks'
import {PlanDemoButton, TryEitjeButton, LoginButton, Text, Layout} from '/src/components'
import {useT} from '../../i18n'
import {handleChangeLanguage} from './helpers'
import {useState} from 'react'
import {useBodyHeight, useApplicationHeadersHeight} from '../../hooks/use_header_height'
import {MobileItem, NestedMobileItem} from './components'
import './styles.less'

export const MobileMenu = () => {
	const taxonomy = useMenuMapping()
	const {lng, t} = useT()
	const headerHeight = useApplicationHeadersHeight()
	const bodyHeight = useBodyHeight()

	return (
		<Layout
			className="mobile-menu"
			direction="vertical"
			height="100%"
			width="100%"
			fixed={`${headerHeight} - - -`}
			maxHeight={bodyHeight}
			gap={16}
			padding="- 24 48 24"
			style={{backgroundColor: Colors.white}}
		>
			<Layout gap={0} direction="vertical" width="100%">
				{taxonomy.map(item => (
					<MenuItem item={item} />
				))}
			</Layout>
			<Layout padding="16 -" gap={12} width="100%" direction="vertical" horizontal="center">
				<TryEitjeButton t="common.try_free" width="full" />
				<PlanDemoButton width="full" colorSet />
				<LoginButton width="full" iconLeft={null} />
				<Text
					className="language-switch"
					onClick={() => handleChangeLanguage(lng)}
					small
					darkGrey
					underline
					t={{
						key: 'common.switch_language',
						language: t(`common.inversed_${lng === 'nl' ? 'en' : 'nl'}`),
					}}
				/>
			</Layout>
		</Layout>
	)
}

const MenuItem = ({item}) => {
	const [isOpen, setIsOpen] = useState()

	const Component = !item.items && item.link ? MobileItem : NestedMobileItem

	return (
		<Layout gap={0} direction="vertical" width="100%" borderBottom>
			<Component item={item} isOpen={isOpen} setIsOpen={setIsOpen} />
		</Layout>
	)
}
