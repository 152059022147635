import {BasicPopout} from '@eitje/web_components'
import {CallButton, MailButton} from '../eitje_button/buttons'

export const ContactPopout = ({mailLink, ...rest}) => {
	return (
		<BasicPopout
			{...rest}
			buttons={[<CallButton t="common.call_us" />, <MailButton t="common.mail_us" colorSet="solid" link={mailLink} />]}
		/>
	)
}
