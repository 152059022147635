import {useWindowWidth} from '/src/contexts'
import {EitjeButton} from '../eitje_button'
import {Layout} from '../layout'

const inlineStyles = {flexWrap: 'wrap', zIndex: 2}

export const ButtonsNavigation = ({activeIndex, onChange, baseColor = 'grey', elements}) => {
	const {mobile} = useWindowWidth()
	return (
		<Layout gap={[8, 12]} horizontal="center" style={inlineStyles}>
			{elements.map((el, i) => (
				<EitjeButton
					key={i}
					onClick={() => onChange(i)}
					colorSet={i === activeIndex ? 'solid' : 'color-bordered-white'}
					baseColor={baseColor}
					height={mobile ? 'mini' : 'small'}
					t={el.name}
				/>
			))}
		</Layout>
	)
}
