import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import _ from 'lodash'
import {Image} from '../image'
import {Text} from '../text'
import {Children, cloneElement, isValidElement} from 'react'
import {showSupportChat} from '../../helpers'
import {Link} from '../link'
import {useI18n} from '/src/i18n'
import {Layout} from '../layout'
import './styles.less'

export const RichText = ({content, richTextImages, alignSelf = 'center', options = {}, fullWidth, width = 600}) => {
	const _options = {
		renderNode: {
			'embedded-asset-block': node => {
				const imageData = richTextImages[node.data.target.sys.id]
				if (!imageData?.image) return null
				return <Image {...imageData} />
			},
			hyperlink: (node, children) => {
				const uri = node.data.uri
				return (
					<Link customSize to={uri}>
						{children}
					</Link>
				)
			},
			'unordered-list': (_, children) => <ul>{children}</ul>,
			'ordered-list': (_, children) => <ol>{children}</ol>,
			'list-item': (_, children) => <li>{children}</li>,
			...options,
		},
	}

	const inlineStyles = {
		alignSelf,
	}

	return (
		<Layout className="rich-text" direction="vertical" gap={0} style={inlineStyles} width={!fullWidth && width} maxWidth="100%">
			{processChildren(documentToReactComponents(JSON.parse(content), _options))}
		</Layout>
	)
}

const ChatText = ({text, supportText}) => {
	const [t] = useI18n()
	return (
		<Text
			className="open-chat eitje-link inline"
			mediumBlue
			data-heap-id="support-chat-inline"
			onClick={e => {
				e.stopPropagation()
				showSupportChat(supportText && t(supportText))
			}}
		>
			{text}
		</Text>
	)
}

const tagMap = {
	openChat: ({text}) => <ChatText text={text} />,
	openIntegrationChat: ({text}) => <ChatText text={text} supportText="intercom.integrations_text" />,
}

const replaceTagWithComponent = (text, tag, Component) => {
	let regexPattern = `\\[${tag}\\](.*?)\\[-${tag}\\]`
	let regex = new RegExp(regexPattern, 'g')
	let parts = []
	let lastIndex = 0

	if (_.isArray(text)) {
		return text
	}

	text.replace(regex, (match, p1, offset) => {
		parts.push(text.substring(lastIndex, offset))
		parts.push(p1.trim()) // capturing the text inside [tag]
		lastIndex = offset + match.length
	})

	if (lastIndex < text?.length) {
		parts.push(text.substring?.(lastIndex).trim())
	}

	parts = parts.filter(part => part) // Remove any empty strings resulting from trim

	if (parts.length === 1) return text
	return parts.map((part, index) => {
		if (index % 2 === 0) return part
		return <Component key={index} text={part} />
	})
}

const processChildren = children => {
	return Children.toArray(children).map(child => {
		if (typeof child === 'string') {
			return Object.entries(tagMap).reduce((content, [tag, Component]) => replaceTagWithComponent(content, tag, Component), child)
		}

		if (isValidElement(child) && child.props) {
			return cloneElement(child, {
				...child.props,
				children: processChildren(child.props.children),
			})
		}

		return child
	})
}
