import {graphql, useStaticQuery} from 'gatsby'
import {Colors} from '@eitje/web_components'
import {Layout, Image, Link} from '/src/components'
import {useWindowWidth, SimpleContextWrapper} from '/src/contexts'
import {MAX_HEADER_WIDTH} from '/src/constants'
import {LeftMenu} from './left_menu'
import {RightMenu} from './right_menu'
import {useHasScrolled} from './hooks'
import {MobileMenu} from './mobile_menu'
import './styles.less'

export const Menu = ({mobileMenuOpen, setMobileMenuOpen}) => {
	const {logo} = useStaticQuery(graphql`
		{
			logo: file(relativePath: {eq: "logo_with_name.png"}) {
				childImageSharp {
					gatsbyImageData(width: 100, layout: FIXED)
				}
			}
		}
	`)
	const hasScrolled = useHasScrolled()
	const {desktop} = useWindowWidth()
	const highContrast = (desktop && hasScrolled) || !desktop
	const backgroundColor = highContrast ? Colors.white : Colors.lightBackground
	const borderColor = highContrast ? Colors.mediumBorder : 'transparent'
	const contextValues = {setMobileMenuOpen}

	return (
		<SimpleContextWrapper {...contextValues}>
			<Layout
				className="eitje-menu"
				vertical="center"
				horizontal="center"
				height={[54, 54, 58]}
				style={{backgroundColor, borderColor}}
				borderBottom
				maxHeight="100vh"
			>
				<Layout horizontal="spaceBetween" maxWidth={MAX_HEADER_WIDTH}>
					<Layout gap={28}>
						<Link to="/">
							<Image image={logo} />
						</Link>
						{desktop && <LeftMenu />}
					</Layout>
					<RightMenu mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
				</Layout>
			</Layout>
			{mobileMenuOpen && <MobileMenu />}
		</SimpleContextWrapper>
	)
}
