import _ from 'lodash'
import {useI18n, useLocale} from '/src/i18n'
import {apiDocsUrl, calendlyUrl, sectorSlugMapping, createAccountUrl} from '/src/constants'
import {Link, Layout, Text} from '/src/components'
import {formatSector} from '/src/helpers'
import {useLocaleAppStoreUrl, useLocaleGooglePlayUrl} from '/src/hooks'
import './styles.less'

import {PRODUCT_PAGES_TAXONOMY} from '/src/components/menu/taxonomy.js'

const sectors = _.map(sectorSlugMapping, (value, key) => ({
	raw_t: `sectors.${value}`,
	link: `/sectors/${key}`,
}))

export const Footer = () => {
	const [t] = useI18n()
	const locale = useLocale()
	const dutch = locale === 'nl'

	const inlineStyles = {breakInside: 'avoid', cursor: 'default'}

	const appStoreUrl = useLocaleAppStoreUrl()
	const googlePlayStoreUrl = useLocaleGooglePlayUrl()
	const appStoreClockSystem = useLocaleAppStoreUrl({clock: true})
	const googlePlayStoreClockSystem = useLocaleGooglePlayUrl({clock: true})

	const columns = [
		{
			title: 'get_started',
			links: [
				{t: 'try_for_free', link: createAccountUrl},
				{t: 'book_demo', link: calendlyUrl},
				{t: 'eitje_in_short', link: '/video'},
				{t: 'contact', link: '/contact'},
			],
		},
		{
			title: 'product',
			links: [
				...PRODUCT_PAGES_TAXONOMY,
				{t: 'iphone_app', link: appStoreUrl},
				{t: 'android_app', link: googlePlayStoreUrl},
				{t: 'appstore_clock', link: appStoreClockSystem},
				{t: 'android_clock', link: googlePlayStoreClockSystem},
			],
		},
		{
			title: 'sector',
			links: [...sectors, dutch && {t: 'seo-planning-hospitality', link: '/personeelsplanning-horeca'}].filter(Boolean),
		},
		{
			title: 'more',
			links: [
				{t: 'integrations_short', link: '/partners'},
				{t: 'become_partner', link: '/partner-worden'},
				{t: 'pricing', link: '/prijzen'},
				{t: 'api_documentation', link: apiDocsUrl},
				{t: 'blog', link: '/blog'},
				{t: 'team', link: '/team'},
				{t: 'vacancies', link: '/vacatures'},
			],
		},
	]

	return (
		<Layout className="footer" direction="vertical" horizontal="center" borderTop maxWidth="100vw">
			<div className="footer-links-columns">
				{columns.map(column => (
					<Layout className={'footer-link-section'} gap={12} direction="vertical" width={[120, 150]} style={inlineStyles}>
						<Text black bold uppercase t={`footer.${column.title}`} />
						{column.links?.map(link => (
							<Link noInitialStyles to={link.link}>
								<Text darkGrey>{formatSector(t(link.raw_t || (link.t && `links.${link.t}`) || link.title))}</Text>
							</Link>
						))}
					</Layout>
				))}
			</div>
			<Layout padding={28} horizontal="center" gap={20} borderTop>
				<Link noInitialStyles to="/">
					© {new Date().getFullYear()} Eitje
				</Link>
				<Link noInitialStyles to="/privacy" text="footer.privacy" />
				<Link noInitialStyles to="/terms-and-conditions" text="footer.conditions" />
			</Layout>
		</Layout>
	)
}
export default Footer
