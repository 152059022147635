import {useState} from 'react'
import {useEventListener} from '@eitje/react-hooks'
import {useStaticQuery, graphql} from 'gatsby'
import {taxonomy} from './taxonomy'
import {flattenGraphQLResponse, formatSector} from '/src/helpers'
import {useI18n} from '/src/i18n'

export const useHasScrolled = () => {
	const [hasScrolled, setHasScrolled] = useState(false)

	const handleScroll = () => {
		setHasScrolled(window.scrollY > 1)
	}

	useEventListener('scroll', handleScroll)

	return hasScrolled
}

export const useMenuMapping = () => {
	const [t] = useI18n()
	const {allContentfulSector} = useStaticQuery(graphql`
		{
			allContentfulSector(sort: {order: ASC}, filter: {node_locale: {eq: "nl"}}) {
				edges {
					node {
						slug
					}
				}
			}
		}
	`)

	const sectors = flattenGraphQLResponse(allContentfulSector).map(s => ({
		title: formatSector(t(`sectors.${s.slug}`)),
		link: `/sectors/${s.slug}`,
	}))

	const sectorItem = {
		title: 'menu.sector',
		link: '/sectors/horeca',
		items: sectors,
	}

	return [...taxonomy.slice(0, 1), sectorItem, ...taxonomy.slice(1)]
}
