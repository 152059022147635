import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {
	ProductTiles,
	Banner,
	ClientLogosBanner,
	PerksBanner,
	ProductPageLayout,
	ProductHighlights,
	PartnerLogosBanner,
	Quotes,
} from '/src/components'

const PERKS = [[{type: 'no_startup_costs_for_clocking'}, {type: 'no_special_device_required'}, {type: 'ios_and_android_app'}]]

const PRODUCTS = [
	[
		'floor_unique_code',
		'floor_track_check_ins_live',
		'happy_support',
		'floor_concise_remuneration',
		'floor_register_breaks_and_meals',
		'floor_register_latecomers',
	],
]

const SCROLL_LINKS = ['floor_unique_code', 'floor_track_check_ins_live', 'floor_concise_remuneration', 'floor_register_breaks_and_meals']

const PageChildren = ({data}) => {
	const {products, quotes, clientLogos, partnerLogos, tiles} = formatData({data})

	return (
		<>
			<PerksBanner type="clock_without_startup_costs" highlights={PERKS[0]} marginTop={0} />
			<ProductHighlights highlights={products} items={PRODUCTS[0]} />
			<Quotes items={quotes[0]} />
			<ClientLogosBanner logos={clientLogos} />
			<ProductTiles type="clocking_product_tiles" items={tiles} />
			<PartnerLogosBanner logos={partnerLogos} />
		</>
	)
}

const Page = ({data}) => {
	const {seo, faq} = formatData({data})

	return (
		<ProductPageLayout name="clocking" blobColor="orange" {...seo} faq={faq} scrollLinks={SCROLL_LINKS}>
			<PageChildren data={data} />
		</ProductPageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					highlights
					shortHighlights
					cta
					name
					asset {
						gatsbyImageData
					}
					preview {
						gatsbyImageData
					}
					previews {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					label
					buttonLink
					buttonText
					paragraph {
						paragraph
					}
				}
			}
		}
		allContentfulPartner(sort: {orderSelection: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logoBlock {
						gatsbyImageData
					}
				}
			}
		}
		allContentfulClient(sort: {order: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logo {
						gatsbyImageData
					}
				}
			}
		}
		contentfulPage(slug: {eq: "kloksysteem"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
			productTiles {
				title
				subtitle
				icon
			}
			featureList
			quotes {
				name
				jobTitle
				image {
					gatsbyImageData
				}
				quote {
					quote
				}
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
