import {Colors} from '@eitje/web_components'
import {Layout} from '../layout'

const inlineStyles = {
	borderBottom: `1px dotted ${Colors.mediumBorder}`, // The color is needed because of legacy css
}

const DottedLine = () => <Layout grow margin="12 40 - -" style={inlineStyles} />

export const InputLayout = ({children}) => {
	const [leftContent, rightContent] = children
	return (
		<Layout width="100%" horizontal="spaceBetween" height={48}>
			<Layout grow>
				{leftContent}
				<DottedLine />
			</Layout>
			{rightContent}
		</Layout>
	)
}
