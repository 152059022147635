import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {
	Banner,
	Blob,
	DelimitedList,
	EitjeButton,
	Image,
	Layout,
	Link,
	PageLayout,
	PerksHighlight,
	Title,
	getResp,
	Youtube,
	ErrorBoundary,
	ViewportWidthBackground,
} from '/src/components'
import {Colors} from '@eitje/web_components'
import utils from '@eitje/utils'
import {useWindowWidth} from '/src/contexts'
import {templatePageSeoProps} from '/src/helpers'

const InnerPage = ({data}) => {
	let {
		name,
		subtitle,
		logoBlock,
		url,
		supportUrl,
		docsUrl,
		supportEmail,
		perksHighlights,
		aboutIntegration,
		functionalities,
		aboutPartner,
		color,
		facebook,
		instagram,
		linkedin,
		productImages,
		youtube,
	} = data.partner

	const {mobile} = useWindowWidth()

	color = color || 'blue' // cant use default in destructure because contenful  returns 'null'

	const bannerButtons = [
		docsUrl && <EitjeButton t="common.activate_integration" link={docsUrl} />,
		supportUrl && <EitjeButton t="common.support" link={supportUrl} />,
		supportEmail && <EitjeButton t="common.mail" link={`mailto:${supportEmail}`} />,
		<EitjeButton t="common.website" link={url} />,
	]

	const topHighlights = perksHighlights?.map?.(p => ({
		type: p.slug,
		hide_cta: true,
	}))

	const bottomHighlights = ['planning', 'hours', 'communication']

	const partnerLinks = [
		{to: url, text: 'common.website'},
		facebook && {
			to: `https://www.facebook.com/${facebook}`,
			text: 'Facebook',
		},
		instagram && {
			to: `https://www.instagram.com/${instagram}`,
			text: 'Instagram',
		},
		linkedin && {
			to: `https://www.linkedin.com/company/${linkedin}`,
			text: 'LinkedIn',
		},
	]

	return (
		<ErrorBoundary>
			<ViewportWidthBackground height={[100, 260]} background={Colors[`light${utils.titleCase(color)}`]}>
				<Blob style={{zIndex: 0}} color={color} number={6} width={getResp(['75%', '100%'])} />
				{/* Key is needed for image to remount when window is resized or it won't adjust its width */}
				<Image key={mobile} image={logoBlock} width={getResp([120, 260])} />
			</ViewportWidthBackground>

			{/* Partner content, dependent on what content they supply us with */}
			<Banner marginTop={60} type="partner" name={name} subtitle={subtitle} buttons={bannerButtons} />
			{topHighlights?.length && <PerksHighlight marginTop={[60, 40]} highlights={topHighlights} />}
			{aboutIntegration && <Banner type="about_integration" paragraph={aboutIntegration} marginTop={[60, 100]} />}
			{/* we removed functionalities from the query because there are no values in contentful yet,
      which causes it to be removed from the schema */}
			{functionalities && <Banner type="functionalities" richText={functionalities} marginTop={[40, 60]} />}
			<Banner type="about_partner" paragraph={aboutPartner} marginTop={[40, 60]} partner={name} />
			<DelimitedList Component={Link} elements={partnerLinks} />
			{youtube && (
				<Layout marginTop={60} width="100%" maxWidth={800}>
					<Youtube video={youtube} />
				</Layout>
			)}
			{productImages?.length && (
				<Layout direction="vertical" gap={16} marginTop={60} maxWidth={800} width="100%">
					{productImages.map(image => (
						<Image width="100%" image={image} />
					))}
				</Layout>
			)}

			{/* Fixed content, always same on each page */}
			<Layout marginTop={[60, 100]} direction="vertical" horizontal="center" gap={32}>
				<Title t="banners.we_are_eitje.title" />
				<PerksHighlight highlights={bottomHighlights} />
			</Layout>
			<Banner type="contact" margin={['60 0', '100 0']} />
		</ErrorBoundary>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})
	const {name} = data.partner
	const seoProps = templatePageSeoProps(name, seo)

	return (
		<PageLayout name="sector" {...seoProps}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($slug: String!, $locale: String) {
		contentfulPage(slug: {eq: "partner"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		contentfulPartner(node_locale: {eq: $locale}, slug: {eq: $slug}) {
			name
			url
			supportEmail
			supportUrl
			partnerTypeNew {
				name
			}
			subtitle {
				subtitle
			}
			aboutIntegration {
				aboutIntegration
			}
			aboutPartner {
				aboutPartner
			}
			logoBlock {
				gatsbyImageData
			}
			productImages {
				gatsbyImageData
			}
			integrationCosts
			docsUrl
			perksHighlights {
				slug
			}
			color
			facebook
			instagram
			linkedin
			youtube
		}
	}
`

export default withFlattenedResponse(Page)
