import _ from 'lodash'
import {Testimonials} from '.'
import {Carousel} from '../carousel'
import {getResp} from '../layout'
import {useWindowWidth} from '/src/contexts'

const chunkArrayToObject = (array, chunkSize) => {
	return _.map(_.chunk(array, chunkSize), chunk => ({items: chunk}))
}

export const TestimonialCarousel = ({items, ...rest}) => {
	const {windowWidth} = useWindowWidth()
	const amountPerSlide = getResp([1, 2, 3])
	const elements = chunkArrayToObject(items, amountPerSlide)
	return <Carousel key={windowWidth} navigation="dots" Component={Testimonials} elements={elements} {...rest} />
}
