import {Layout} from '../layout'
import {Row} from './row'
import './styles.less'

export const SectionBody = ({features, open, columns, columnWidths}) => {
	if (!open) return null
	return (
		<Layout className="feature-table-section-body" gap={0} direction="vertical" width="100%">
			{features.map(feature => (
				<Row feature={feature} columns={columns} columnWidths={columnWidths} />
			))}
		</Layout>
	)
}
