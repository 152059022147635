import {Banner, FeatureButtons, Logos, PerksHighlight} from '/src/components'
import {isSupportOpen} from '/src/helpers'

export const SupportBanner = ({marginTop = 100, ...rest}) => {
	const type = isSupportOpen ? 'very_quick_support' : 'best_support'

	return <Banner type={type} marginTop={marginTop} {...rest} />
}

export const ProductPagesBanner = ({hide, ...rest}) => {
	return (
		<Banner {...rest} type="discover_more_advantages" marginTop={100} subtitle={false}>
			<FeatureButtons hide={hide} />
		</Banner>
	)
}

export const LogoBanner = ({logos, marginTop = 120, ...rest}) => {
	return (
		<Banner marginTop={marginTop} {...rest}>
			<Logos logos={logos.slice(0, 9)} />
		</Banner>
	)
}

export const ClientLogosBanner = props => {
	return <LogoBanner {...props} type="join_many_companies" />
}

export const PartnerLogosBanner = ({marginTop = 120, ...rest}) => {
	return <LogoBanner {...rest} marginTop={marginTop} type="integrations_index" />
}

export const PerksBanner = ({type, highlights, marginTop = 100, ...rest}) => {
	return (
		<Banner type={type} marginTop={marginTop} {...rest}>
			<PerksHighlight highlights={highlights} />
		</Banner>
	)
}
