import React from 'react'
import {Layout} from '../layout'
import {Text, Title} from '../text'
import {UserInfo} from '../user_info'
import {useI18n} from '/src/i18n'

export const Quote = ({quote, image, name, jobTitle, parentGap, size = 'regular'}) => {
	const text = makeQuote({text: quote})
	const isSmall = size === 'small'
	const textProps = isSmall ? {darkGrey: true, fontSize: 16} : {large: true, weight: 'regular', lineHeight: 2}

	return (
		<Layout direction="vertical" horizontal="center" gap={12} maxWidth={600} parentGap={parentGap} className="quote">
			<Text className="quote-text" italic center {...textProps} children={text} />
			<UserInfo name={name} image={image} subtext={jobTitle} size={size} />
		</Layout>
	)
}

export const makeQuote = ({t: _t, text}) => {
	const [t] = useI18n()
	const _text = text || t(_t)

	const quoteSigns = ['“', '”', '"', "'"]
	const quotize = fn => (quoteSigns.some(sign => fn(_text, sign)) ? '' : '"')
	const start = quotize(_.startsWith)
	const end = quotize(_.endsWith)

	return `${start}${_text}${end}`
}
