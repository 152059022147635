import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import {withFlattenedResponse} from '/src/helpers'
import {
	Banner,
	BrowserWithBlob,
	Carousel,
	EitjeButton,
	Try28DaysButton,
	Logos,
	PageLayout,
	PerksHighlight,
	ProductHighlights,
	Quotes,
	ClientLogosBanner,
	PartnerLogosBanner,
	ProductTiles,
	Youtube,
	TitleCarousel,
	TestimonialCarousel,
	SeoBlocks,
} from '/src/components'
import {graphql} from 'gatsby'
import '../styles/pages/homepage.less'
import {createAccountUrl} from '../constants'
import {getCarouselElements, scrollToId, mapTestimonialsToImage, formatData} from '/src/helpers'

const PERKS = [
	['lowest_price', 'easy_to_use', 'support'],
	[
		{type: 'monthly', hide_cta: true},
		{type: 'lowest_price', hide_cta: true},
		{type: 'integrations', hide_cta: true},
	],
]

const PRODUCTS = [
	['happy_support'],
	['planning-homepage', 'hours-homepage', 'plan-appointment', 'communication-homepage', 'homepage-financial'],
	['mobile-app'],
]

const TESTIMONIALS = ['hr_manager', 'teamleader', 'entrepeneur']

const imgStyle = {
	objectFit: 'cover',
	objectPosition: 'center',
}

const InnerPage = ({data}) => {
	const {partnerLogos, clientLogos, tiles, products, quotes, asset} = formatData({data})
	const elements = getCarouselElements(asset)
	const testimonials = mapTestimonialsToImage(TESTIMONIALS, asset)

	return (
		<>
			<TitleCarousel />
			<Banner type="homepage" />
			<Carousel marginTop="60 - 12 -" Component={BrowserWithBlob} elements={elements} />
			<EitjeButton t="links.all_features" colorSet="solid" onClick={() => scrollToId('planning-homepage')} />
			<Banner type="advantages_eitje" marginTop={100}>
				<PerksHighlight highlights={PERKS[0]} />
				<Try28DaysButton link={createAccountUrl} />
			</Banner>
			<PartnerLogosBanner logos={partnerLogos} />
			<ProductHighlights highlights={products} items={PRODUCTS[0]} />
			<Quotes items={quotes[0]} marginTop={[40, 60]} />
			<Banner type="try_for_free" marginTop={100} />
			<ProductHighlights highlights={products} items={PRODUCTS[1]} coloringOffsetAmount={1} />
			<Banner type="no_hidden_costs" marginTop={100}>
				<PerksHighlight highlights={PERKS[1]} />
			</Banner>
			<ProductHighlights highlights={products} items={PRODUCTS[2]} coloringOffsetAmount={2} />
			<Banner type="eitje_for_everybody" marginTop={120}>
				<TestimonialCarousel items={testimonials} sharedProps={{imgStyle}} />
			</Banner>
			<Banner type="eitje_video" marginTop={120}>
				<Youtube video="eitje-intro" />
			</Banner>
			<ClientLogosBanner logos={clientLogos} />
			<Banner type="sector_advantages" marginTop={120}>
				<ProductTiles items={tiles} />
			</Banner>
			<Banner type="eitje_fit" marginTop={120} />
			<Banner type="all_features" marginTop={120} />
			<SeoBlocks marginTop={32} />
			<Banner type="try_for_free" marginTop={80} />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})

	return (
		<PageLayout name="home" {...seo} data={data}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		allContentfulAsset(
			filter: {
				node_locale: {eq: $locale}
				title: {
					in: [
						"planning-roster"
						"hours-export-payroll"
						"hours-clock-out"
						"planning-financial"
						"communication-news-hospitality"
						"hr_manager portret"
						"teamleader portret"
						"entrepeneur portret"
					]
				}
			}
		) {
			edges {
				node {
					title
					gatsbyImageData
				}
			}
		}
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					cta
					name
					asset {
						gatsbyImageData
					}
					previews {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					label
					buttonLink
					buttonText
					paragraph {
						paragraph
					}
				}
			}
		}
		allContentfulPartner(sort: {orderSelection: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logoBlock {
						gatsbyImageData
					}
				}
			}
		}
		allContentfulClient(sort: {order: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logo {
						gatsbyImageData
					}
				}
			}
		}
		contentfulPage(slug: {eq: "homepage"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
			productTiles {
				title
				subtitle
				icon
				url
			}
			quotes {
				name
				jobTitle
				image {
					gatsbyImageData
				}
				quote {
					quote
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
