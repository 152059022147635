import {useEffect, useState} from 'react'

const delay = 2500

export const useDelayedAnimating = () => {
	const [isAnimating, setIsAnimating] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsAnimating(true)
		}, delay)

		return () => clearTimeout(timer)
	}, [])

	return isAnimating
}
