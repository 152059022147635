import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {Banner, FeatureTable, FAQ, PageLayout, PerksHighlight, getResp} from '/src/components'
import {CostCalculator} from '/src/components/cost_calculator'
import {PricingCards} from '/src/components/pricing_card/pricing_cards'

const PRODUCTS = [
	['monthly', 'integrations', {type: 'no_hidden_costs', hide_cta: true}],
	[
		{type: 'support', hide_cta: true},
		{type: 'features', hide_cta: true},
		{type: 'startup_costs', hide_cta: true},
	],
]

const InnerPage = ({data}) => {
	const {frequentlyAskedQuestion: frequentlyAskedQuestions, featureTable} = data

	return (
		<>
			<PerksHighlight highlights={PRODUCTS[0]} marginTop={60} />
			<Banner type="no_startup_costs" marginTop={[86, 120, 144]} />
			<PricingCards marginTop={72} />
			<CostCalculator marginTop={120} />
			<Banner type="features" marginTop={[84, 120]} gap={32}>
				<PerksHighlight highlights={PRODUCTS[1]} />
			</Banner>
			<Banner type="features_per_tier" marginTop={120} />
			<FeatureTable
				marginTop={20}
				tableName="core_features_table"
				columns={['planning', 'hours', 'clock']}
				items={featureTable?.chapters}
			/>
			<Banner type="contact" marginTop={120} />
			<FAQ margin={getResp(['86 0', '120 0'])} items={frequentlyAskedQuestions} />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})
	return (
		<PageLayout name="pricing" banner="pricing" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "prijzen"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
		contentfulFeatureTable(slug: {eq: "feature_table"}, node_locale: {eq: $locale}) {
			chapters {
				title
				features {
					name
					tier
					tooltipTitle
					tooltipText {
						tooltipText
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
