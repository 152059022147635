import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {
	Banner,
	Paragraph,
	Testimonials,
	VacancyPreview,
	ProductTiles,
	Image,
	PageLayout,
	Layout,
	PerksBanner,
	VacancyImages,
} from '/src/components'
import {mapTestimonialsToImage} from '/src/helpers'
import {useI18n} from '/src/i18n'

const HIGHLIGHTS = ['shine', 'teamwork', 'work_pleasure']
const NAMES = ['clara', 'tiemo', 'lisa']

const InnerPage = ({data}) => {
	const {rawData, asset} = formatData({data})
	const tiles = rawData.tile
	const {textBlock, vacancy} = rawData
	const [t] = useI18n()
	const colleagues = mapTestimonialsToImage(NAMES, asset)
	const teamPhoto = asset.find(a => a.title === 'team-working')

	const openApplication = {
		name: 'common.open_application',
		salaryRange: 'common.salary_negotionable',
		popoutTitle: 'popouts.open_application.title',
		popoutBody: t('popouts.open_application.text'),
	}
	const vacancies = [...vacancy, openApplication]

	return (
		<>
			<Image className="image-full-width" width="100%" style={{marginTop: 40, borderRadius: 4}} image={teamPhoto} />
			<Banner type="open_vacancies" marginTop={60}>
				<Layout gap={0} direction="vertical" width="full">
					{vacancies.map(VacancyPreview)}
				</Layout>
			</Banner>

			<ProductTiles type="our_philosophy" items={tiles} />
			<Banner type="future_colleagues" marginTop={100}>
				<Testimonials items={colleagues} />
			</Banner>

			<Paragraph {...textBlock} marginTop={100} />
			<Banner type="talk_to_you" marginTop={60} />
			<VacancyImages assets={asset} />
			<PerksBanner type="work_with_us" highlights={HIGHLIGHTS} margin="100 - 120 -" />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})

	return (
		<PageLayout name="vacancies" banner="working_at_eitje" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "vacatures"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		allContentfulAsset(
			filter: {
				node_locale: {eq: $locale}
				title: {
					in: [
						"team-working"
						"office clara"
						"office street"
						"team happy"
						"office abel"
						"Tiemo portret - lage resolutie"
						"Clara portret - lage resolutie"
						"Lisa portret - lage resolutie"
					]
				}
			}
		) {
			edges {
				node {
					title
					gatsbyImageData
				}
			}
		}
		allContentfulTile(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, tags: {eq: "vacancy"}}) {
			edges {
				node {
					title
					icon
					subtitle
				}
			}
		}
		allContentfulVacancy(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					text {
						raw
					}
					updatedAt
					salaryRange
					name
					slug
				}
			}
		}
		contentfulTextBlock(slug: {eq: "why_eitje"}, node_locale: {eq: $locale}) {
			text {
				raw
			}
			title
		}
	}
`

export default withFlattenedResponse(Page)
