const featureItems = [
	'rF0T',
	'rF1T',
	'rF2T',
	'rF3T',
	'cF1T',
	'uF1T',
	'uF2T',
	'iF1T',
	'rF4T',
	'uF3T',
	'uF4T',
	'uF5T',
	'rF5T',
	'rF6T',
	'uF6T',
	'uF7T',
	'uF8T',
	'cF2T',
	'iF2T',
	'iF3T',
	'cF3T',
	'iF4T',
	'cF4T',
	'cF5T',
]

// We infer the info because there is only 1 letter difference and this makes the mapping a lot easier to read
// Example of original:
// { title: "rF0T", info: "rF0I" }
export const featureList = featureItems.map(item => ({
	title: `seo_cards.${item}`,
	info: `seo_cards.${item.replace('T', 'I')}`,
}))
