import {changeLocale} from 'gatsby-plugin-react-intl'

export const changeLanguage = languageCode => {
	if (window.Intercom) {
		window.Intercom('update', {language_override: languageCode})
	}
	changeLocale(languageCode)
}

export const isPathIgnored = () => {
	const currentPath = typeof window !== 'undefined' && window.location.pathname

	return ['/blog*', '/blog/*'].some(path => {
		const regex = new RegExp('^' + path.replace(/\*/g, '.*') + '$')
		return regex.test(currentPath)
	})
}
