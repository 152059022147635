import {useState} from 'react'
import {useAsyncEffect} from '@eitje/react-hooks'
import _ from 'lodash'

export const useGetCountry = () => {
	const [country, setCountry] = useState()

	useAsyncEffect(async () => {
		try {
			const res = await fetch('https://pro.ip-api.com/json/?key=Dos4yZ2In7Mk4Jo')
			const data = await res.json()

			setCountry(data?.country)
		} catch (error) {
			setCountry(null)
		}
	}, [])

	return country
}
