import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import _ from 'lodash'
import {useWindowWidth} from '/src/contexts'
import {Banner, ProfileCard, PageLayout, Layout, getResp, PerksHighlight} from '/src/components'

const HIGHLIGHTS = ['shine', 'teamwork', 'work_pleasure']

const InnerPage = ({data}) => {
	const {teammember: teamMembers} = data
	const {smallMobile, mobile} = useWindowWidth()
	const teamMemberGap = getResp([12, 32, 40])
	const profileWidth = smallMobile ? '100%' : mobile ? '50%' : '33.333%'

	const inlineStyles = {flexWrap: 'wrap'}

	return (
		<>
			<Layout marginTop={[20, 40]} gap={[12, 32, 40]} width="100%" vertical="start" horizontal="center" style={inlineStyles}>
				{_.sortBy(teamMembers, 'order').map(member => (
					<Layout width={`calc(${profileWidth} - ${teamMemberGap}px - 1px)`}>
						<ProfileCard item={member} />
					</Layout>
				))}
				<Banner type="work_with_us_link" marginTop={[80, 100]} gap={40}>
					<PerksHighlight highlights={HIGHLIGHTS} margin="40 0" />
				</Banner>
			</Layout>
			<Banner type="our_story" marginTop={[60, 100]} />
			<Banner type="contact" margin="100 0 80 0" />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})

	return (
		<PageLayout name="team" banner="we_are_eitje" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "team"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		allContentfulTeammember(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					mail
					name
					profilePicture {
						gatsbyImageData
					}
					linkedin
					order
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
