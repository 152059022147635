import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {Banner, FAQ, Layout, PageLayout, Paragraph, ParagraphList, getResp} from '../components'

const InnerPage = ({data}) => {
	const {faq, paragraphs} = formatData({data})

	return (
		<>
			<ParagraphList items={paragraphs} marginTop={40} />
			<FAQ marginTop={[40, 100]} items={faq} />
			<Banner type="contact_no_video_button" margin={getResp(['40 - 72 -', '60 - ', '60 - 120 -'])} />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})

	return (
		<PageLayout name="terms-and-conditions" banner="conditions" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "terms-and-conditions"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		contentfulTextPage(node_locale: {eq: $locale}, slug: {eq: "conditions"}) {
			textBlocks {
				text {
					raw
				}
				title
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
