import {useI18n} from '/src/i18n'
import {Link, DelimitedList, Layout} from '/src/components'
import {scrollToId} from '/src/helpers'
import './styles.less'
import _ from 'lodash'

const MAX_LINKS_VISIBLE = 5
const SHOW_MORE_THRESHOLD = 3

export const ScrollToLinks = ({links}) => {
	const [t] = useI18n()

	// ensure we always show a maximum of links
	const visibleLinks = links.slice(0, MAX_LINKS_VISIBLE)

	const translatedLinks = visibleLinks.map((s, i) =>
		// When there are more than SHOW_MORE_THRESHOLD items, we want the last item to read "show more"
		visibleLinks.length > SHOW_MORE_THRESHOLD && i === visibleLinks.length - 1 ? _.capitalize(t('links.more')) : t(`links.${s}`),
	)

	const handleLinkClick = (id, index) => scrollToId(links[index])

	return (
		<Layout className="scroll-to-links">
			<DelimitedList elements={translatedLinks} Component={Link} componentProps={{onClick: handleLinkClick}} />
		</Layout>
	)
}
