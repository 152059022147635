import {useState} from 'react'
import {Layout} from '../layout'
import {SectionHeader} from './section_header'
import {SectionBody} from './section_body'
import './styles.less'

const columnWidths = [42, 50, 160]
const tableName = 'core_features'

export const FeatureTable = ({columns, items, ...rest}) => {
	return (
		<Layout id="feature-table" width="100%" className="feature-table" direction="vertical" gap={40} {...rest}>
			{items.map(item => (
				<Section {...item} columns={columns} />
			))}
		</Layout>
	)
}

export const Section = props => {
	const [open, setIsOpen] = useState(true)
	const _props = {
		...props,
		open,
		setIsOpen,
		tableName,
		columnWidths,
	}
	return (
		<Layout width="100%" className="feature-table-section" direction="vertical" gap={0}>
			<SectionHeader {..._props} />
			<SectionBody {..._props} />
		</Layout>
	)
}
