import {isDev} from '../constants'

export const handleToggleIgnoreTranslations = () => {
	if (typeof localStorage === 'undefined' || !isDev) return
	const ignoreTranslations = localStorage.getItem('ignoreTranslations')

	if (ignoreTranslations) {
		localStorage.removeItem('ignoreTranslations')
	} else {
		localStorage.setItem('ignoreTranslations', true)
	}
	window.location.reload()
}
