import React from 'react'
import {useWindowWidth} from '/src/contexts'
import {Layout} from '../layout'
import {Quote} from '../quote'
import utils from '@eitje/utils'

export const Quotes = ({items, size, marginTop = 120, ...rest}) => {
	const quotes = utils.alwaysArray(items)
	const {mobile} = useWindowWidth()
	return (
		<Layout gap={40} childrenPerRow={mobile ? 1 : quotes.length} marginTop={marginTop} horizontal="center" width="100%" {...rest}>
			{quotes.map(quote => (
				<Quote parentGap={40} size={size} {...quote} />
			))}
		</Layout>
	)
}
