import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {Banner, PartnerLogosBanner, PageLayout, PerksHighlight, ProductHighlights, ClientLogosBanner} from '/src/components'

const PERKS = [
	[
		{type: 'monthly', hide_cta: true},
		{type: 'lowest_price', hide_cta: true},
		{type: 'integrations', hide_cta: true},
	],
]

const PRODUCTS = [
	['planning', 'time_registration'],
	['clocking', 'communication', 'knowledge_base'],
	['financial'],
	['mobile_app', 'exports'],
]

const InnerPage = ({data}) => {
	const {clientLogos, partnerLogos, products} = formatData({data})

	return (
		<>
			<ProductHighlights highlights={products} items={PRODUCTS[0]} coloringOffsetAmount={6} />
			<Banner type="no_hidden_costs" marginTop={100}>
				<PerksHighlight highlights={PERKS[0]} />
			</Banner>
			<ProductHighlights highlights={products} items={PRODUCTS[1]} coloringOffsetAmount={3} />
			<ClientLogosBanner logos={clientLogos} />
			<ProductHighlights highlights={products} items={PRODUCTS[2]} coloringOffsetAmount={6} />
			<PartnerLogosBanner logos={partnerLogos} />
			<ProductHighlights highlights={products} items={PRODUCTS[3]} coloringOffsetAmount={6} />
			<Banner type="try_for_free" marginTop={120} />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})

	return (
		<PageLayout name="all-features" banner="one_spot" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "tools"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					paragraph {
						paragraph
					}
					cta
					name
					asset {
						gatsbyImageData
					}
					preview {
						gatsbyImageData
					}
					previews {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					buttonLink
					buttonText
					label
					textBlocks {
						title
						text {
							raw
						}
					}
				}
			}
		}
		allContentfulClient(sort: {order: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logo {
						gatsbyImageData
					}
				}
			}
		}
		allContentfulPartner(sort: {orderSelection: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logoBlock {
						gatsbyImageData
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
