import {HORIZONTAL_BROWSER_MARGIN} from '.'
import {useWindowWidth} from '/src/contexts'
import {Browser} from '../../browser'
import {Carousel} from '../../carousel'
import {Image} from '../../image'
import {Layout, getResp} from '../../layout'
import {Phone} from '../../phone'

const MobilePreview = ({images, isInversed}) => {
	const {mobile} = useWindowWidth()
	const mobileGap = getResp([0, 40, 200])
	const rightMargin = isInversed ? mobileGap : '-'
	const leftMargin = isInversed ? '-' : mobileGap
	const mobileMargin = `- ${rightMargin} - ${leftMargin}`

	return (
		<Layout width={[170, 170, 180]} horizontal="center" margin={mobileMargin} gap={!images ? 4 : getResp([16, 32])}>
			{images.map(m => (
				<Layout height={mobile ? 250 : 372}>
					<Phone {...m} />
				</Layout>
			))}
		</Layout>
	)
}

export const Preview = ({isInversed, mobilePreview, previews, imageTitle, image, asset}) => {
	const {tablet} = useWindowWidth()

	if (asset) {
		return <Image image={asset} style={{maxWidth: '100%'}} width={getResp(['100%', 340, 420])} />
	}

	if (mobilePreview) return <MobilePreview isInversed={isInversed} images={mobilePreview} />

	if (previews?.length)
		return <Carousel Component={Browser} elements={previews.map(image => ({image: image.gatsbyImageData}))} navigation="dots" />

	return <Browser alt={imageTitle} image={image} margin={tablet && `0 ${HORIZONTAL_BROWSER_MARGIN}`} />
}
