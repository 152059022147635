import {CheckedItem} from '.'
import {useWindowWidth} from '/src/contexts'
import {Banner} from '/src/components'

export const CheckedItemList = ({type, items, marginTop = 100, columnCount = 3, ...rest}) => {
	const {mobile} = useWindowWidth()

	const inlineStyles = {
		columnCount: mobile ? 1 : columnCount,
		columnGap: 48,
	}
	return (
		// We use a div here so we can utilize column-count, which does not work with flex
		<Banner type={type} marginTop={marginTop} gap={32}>
			<div className="checked-item-list" style={inlineStyles} {...rest}>
				{items.map(item => (
					<CheckedItem t={item} />
				))}
			</div>
		</Banner>
	)
}
