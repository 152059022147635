import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {Banner, Layout, PageLayout, ProductHighlights, getResp} from '/src/components'
import {FeaturedBlogArticleCard} from '/src/components/blog_article_card/featured_blog_article_card'
import _ from 'lodash'
import {BlogArticleCard} from '/src/components/blog_article_card'

const PRODUCTS = [['advantages_eitje']]

const InnerPage = ({data}) => {
	let {blogs, products} = formatData({data})

	blogs = blogs.map(blog => ({...blog, sortFeatured: blog.featured === true ? 1 : 0}))
	blogs = blogs.filter(p => p.image?.gatsbyImageData)
	blogs = _.orderBy(blogs, ['sortFeatured', 'publishedAt'], ['desc', 'desc'])

	const featuredBlog = blogs[0]
	const nonFeaturedBlogs = blogs.slice(1).map(BlogArticleCard)

	return (
		<>
			<Layout marginTop={60} childrenPerRow={getResp([1, 2, 3])} width="100%" gap={[40, 56]} vertical="start">
				<FeaturedBlogArticleCard {...featuredBlog} />
				{nonFeaturedBlogs}
			</Layout>
			<Banner type="try_for_free" marginTop={120} />
			<ProductHighlights highlights={products} items={PRODUCTS[0]} coloringOffsetAmount={1} />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})

	return (
		<PageLayout name="blog" banner="blog" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "blog"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}
		allContentfulBlogPost(filter: {node_locale: {eq: "nl"}}, sort: {publishedAt: DESC}) {
			edges {
				node {
					title
					publishedAt(formatString: "YYYY-MM-DD")
					slug
					excerpt
					subtitle
					featured
					category {
						title
					}
					image {
						gatsbyImageData
					}
					author {
						name
						avatar {
							gatsbyImageData
						}
					}
				}
			}
		}
		allContentfulCustomerStory(filter: {node_locale: {eq: "nl"}}, sort: {publishedAt: DESC}) {
			edges {
				node {
					title
					publishedAt(formatString: "YYYY-MM-DD")
					slug
					excerpt
					subtitle
					category {
						title
					}
					image {
						gatsbyImageData
					}
					author {
						name
						avatar {
							gatsbyImageData
						}
					}
				}
			}
		}
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					paragraph {
						paragraph
					}
					cta
					name
					previews {
						gatsbyImageData
					}
					buttonLink
					buttonText
					label
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
