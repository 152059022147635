import {useEffect} from 'react'
import useSwipe from '../../hooks/use_swipe'
import {handleStartOrReset} from './helpers'

export const useStartResetCarousel = props => {
	const {interval, isComponentHovered, isAutomatic, intervalRef, elements} = props

	useEffect(() => {
		if (!interval || isComponentHovered || !isAutomatic) return

		handleStartOrReset(props)

		return () => {
			if (intervalRef.current) clearInterval(intervalRef.current)
		}
	}, [elements, interval, isComponentHovered, isAutomatic])
}

export const useCarouselSwipe = ({elements, activeIndex, setActiveIndex}) => {
	return useSwipe({
		onSwipeLeft: () => {
			const newIndex = activeIndex + 1 >= elements.length ? 0 : activeIndex + 1
			setActiveIndex(newIndex)
		},
		onSwipeRight: () => {
			const newIndex = activeIndex === 0 ? elements.length - 1 : activeIndex - 1
			setActiveIndex(newIndex)
		},
	})
}

export const useSetAutomatic = ({setIsAutomatic, wasInViewport, intervalRef}) => {
	useEffect(() => {
		setIsAutomatic(!wasInViewport)
		wasInViewport && clearInterval(intervalRef.current)
	}, [wasInViewport])
}
