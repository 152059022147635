import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {Banner, FAQ, PageLayout, getResp} from '/src/components'
import {ParagraphList} from '/src/components/paragraph/paragraph_list'

const InnerPage = ({data}) => {
	const {
		textPage: {textBlocks},
		frequentlyAskedQuestion: frequentlyAskedQuestions,
	} = data

	return (
		<>
			<ParagraphList items={textBlocks} marginTop={40} type="orderedList" />
			<FAQ marginTop={[40, 100]} items={frequentlyAskedQuestions} />
			<Banner type="contact_no_video_button" margin={getResp(['40 - 72 -', '60 - ', '60 - 120 -'])} />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})

	return (
		<PageLayout name="privacy" banner="privacy" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "privacy"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		contentfulTextPage(node_locale: {eq: $locale}, slug: {eq: "privacy"}) {
			textBlocks {
				text {
					raw
				}
				title
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {fields: order}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
