import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {ProductPageLayout, ProductHighlights, Quotes, PartnerLogosBanner, ProductTiles} from '/src/components'

const PRODUCTS = [
	['financial_live_results'],
	['financial_forecasts'],
	['financial_allowances', 'financial_export_financial_data', 'plan-appointment'],
]

const SCROLL_LINKS = ['financial_live_results', 'financial_forecasts', 'financial_allowances', 'financial_export_financial_data']

const PageChildren = ({data}) => {
	const {quotes, products, tiles, partnerLogos} = formatData({data})

	return (
		<>
			<ProductHighlights highlights={products} items={PRODUCTS[0]} coloringOffsetAmount={2} />
			<Quotes items={quotes[0]} />
			<ProductHighlights highlights={products} items={PRODUCTS[1]} coloringOffsetAmount={3} />
			<Quotes items={quotes[1]} />
			<PartnerLogosBanner logos={partnerLogos} />
			<ProductHighlights highlights={products} items={PRODUCTS[2]} />
			<ProductTiles type="financial_product_tiles" items={tiles} />
		</>
	)
}

const Page = ({data}) => {
	const {seo, faq} = formatData({data})

	return (
		<ProductPageLayout name="financial" blobColor="blue" {...seo} faq={faq} scrollLinks={SCROLL_LINKS}>
			<PageChildren data={data} />
		</ProductPageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					highlights
					shortHighlights
					cta
					name
					asset {
						gatsbyImageData
					}
					preview {
						gatsbyImageData
					}
					previews {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					label
					buttonLink
					buttonText
					paragraph {
						paragraph
					}
				}
			}
		}
		allContentfulPartner(sort: {orderSelection: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logoBlock {
						gatsbyImageData
					}
				}
			}
		}
		contentfulPage(slug: {eq: "financieel"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
			productTiles {
				title
				subtitle
				icon
			}
			featureList
			quotes {
				name
				jobTitle
				image {
					gatsbyImageData
				}
				quote {
					quote
				}
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
