import React from 'react'
import {Image} from '../image'
import {Layout} from '../layout'
import PhoneFrame from './phone-preview.png'

import './styles.less'

export const Phone = item => {
	return (
		<Layout className="phone" relative height="100%">
			<Image image={item} className="phone-asset" />
			<img className="phone-frame" height="100%" src={PhoneFrame} alt="phone frame" />
		</Layout>
	)
}
