import _ from 'lodash'
import {changeLanguage} from '../../helpers/change_language'

export const handleChangeLanguage = lng => {
	changeLanguage(lng === 'nl' ? 'en' : 'nl')
}

export const isCurrentPage = link => {
	let url = typeof window !== 'undefined' && _.trimEnd(window.location.pathname, '/')
	if (!url) return
	return link === url.replace('/en', '')
}

export const isSubItemActive = items => items?.some(item => isCurrentPage(item.link))
