import _ from 'lodash'
import {showSupportChat} from '/src/helpers'

const chat = {
	link: 'chat',
	onClick: () => showSupportChat(''),
}

// When the value of an instance is a string instead of an object
// it means we only set the animation
const instances = {
	support: {
		animation: 'support',
		...chat,
	},
	integrations: {
		animation: 'cloud-network',
		link: 'integrations',
	},
	integrations_no_extra_charge: {
		animation: 'cloud-network',
		link: 'try_eitje_free',
	},
	no_hidden_costs: {
		animation: 'search-money',
		link: 'prices',
	},
	shine: 'training',
	teamwork: 'high-five',
	work_pleasure: 'confetti',

	monthly: {
		animation: 'waving-people',
		...chat,
	},
	features: {
		animation: 'high-five',
		link: 'features',
	},
	startup_costs: {
		animation: 'coins',
		link: 'prices',
	},
	request_integration: {
		animation: 'light-bulb',
		...chat,
	},
	open_api: {
		animation: 'hammer',
		link: 'api_docs',
	},
	easy_to_use: {
		animation: 'sunbathing',
		link: 'features',
	},
	lowest_price: {
		animation: 'coins',
		link: 'prices',
	},
	no_startup_costs_for_clocking: 'coins',
	no_special_device_required: 'hammer',
	ios_and_android_app: 'download',
	load_teammembers: 'waving-people',
	load_teammember_contracts: 'waving-people',
	contracts: 'contract',
	export_hours: 'clock',
	financial_results: 'coins',
	labour_costs: 'coins',
	allowances: 'receipt',
	meals: 'apple',
	live_revenue: 'money-with-arrows-up',
	revenue_type: 'weighing-scale',
	revenue_forecast: 'bar-chart',
	planning: 'calendar',
	hours: 'clock',
	communication: 'chat',
	first_contact: 'bird-with-mail',
	online_meeting: 'waving-people',
	building: 'hammer',
	technical_knowledge: 'construction-worker',
	great_support: 'support',
	marketing: 'eye',
	new_business: 'egg-with-ribbon',
	one_location: 'chick',
	multiple_locations: 'nest',
}

export const highlightElementMapping = element => {
	const currentInstance = instances[element.type]

	// Return to prevent crashes while developing in the absence of Contentful data.
	if (!currentInstance) return null

	return {
		...currentInstance,
		animation: _.isString(currentInstance) ? currentInstance : currentInstance.animation,
		...element,
	}
}
