export const sectorSlugMapping = {
	horeca: 'hospitality',
	hotels: 'hotels',
	winkels: 'retail',
	strandtenten: 'beachclubs',
	sport_en_recreatie: 'sportsandleisure',
	campings: 'campings',
	bakkers: 'bakeries',
	ijssalons: 'icecreamparlours',
}

export const packageCosts = {
	planning: {price: 2.4, incremental: 2.4},
	hours: {price: 3.6, incremental: 1.2},
	clock: {price: 4, incremental: 0.4},
}

// Eitje info
export const eitjePhoneNumber = '0031202616671'
export const salesPhoneNumber = '0031202611580'
export const eitjePhoneNumberFormatted = '020 - 261 6671'
export const salesPhoneNumberFormatted = '020 - 261 1580'
export const eitjeMail = 'info@eitje.app'
export const officeStreet = 'Lijnbaansstraat 17-1'
export const officeZipCode = '1016ST Amsterdam'
export const cocNumber = '75561050'
export const vatNumber = 'NL860323924B01'

// External URLs
export const webAppUrl = 'https://web.eitje.app'
export const createAccountUrl = 'https://web.eitje.app/create_account'
export const videoUrl = 'https://www.youtube.com/watch?v=6dGFIvQ0Q7Q'
export const calendlyUrl = 'https://calendly.com/eitje-joris/eitje-demo'
export const facebookUrl = 'http://www.facebook.com/eitjeapp'
export const instagramUrl = 'http://www.instagram.com/eitje.app'
export const youtubeUrl = 'https://www.youtube.com/@eitje-app'
export const linkedInUrl = 'https://www.linkedin.com/company/eitje/'
export const apiDocsUrl = 'https://documenter.getpostman.com/view/443091/2s9YXk2gBR#5ab26a2c-20b6-4887-a487-6a9a340415d8'
export const intercomUrl = 'https://intercom.help/eitje/'
export const tikTokUrl = 'https://www.tiktok.com/@eitje.app'
export const googleMapsUrl =
	'https://www.google.nl/maps/place/Eitje/@52.3694638,4.8785481,18z/data=!4m6!3m5!1s0x47c609ed5fb82f07:0x693850dcda602d78!8m2!3d52.3695199!4d4.879189!16s%2Fg%2F11frq_d8h5'

export const MAX_PAGE_WIDTH = 1000
export const MAX_HEADER_WIDTH = 1440
export const TEXT_PAGE_WIDTH = 800

export const isProduction = process.env.GATSBY_IS_PROD
export const isDev = !isProduction
