import _ from 'lodash'
import {apiDocsUrl, calendlyUrl, salesPhoneNumber, createAccountUrl} from '/src/constants'
import {showSupportChat, scrollToId} from '/src/helpers'

const links = {
	api_docs: {
		text: 'common.see_docs',
		to: apiDocsUrl,
	},
	see_api_docs: {
		to: apiDocsUrl,
	},
	become_partner: {
		to: '/partner-worden',
	},
	chat: {
		onClick: showSupportChat,
		'data-heap-id': 'support-chat-link',
	},
	chat_questions: {
		onClick: showSupportChat,
		'data-heap-id': 'support-chat-link',
	},
	integrations: {
		to: '/partners',
	},
	all_partners: {
		to: '/partners',
	},
	prices: {
		to: '/prijzen',
	},
	call: {
		text: 'common.call_us',
		to: `tel:${salesPhoneNumber}`,
	},
	plan_demo: {
		to: calendlyUrl,
	},
	try_eitje_free: {
		text: 'common.try_eitje_free',
		to: createAccountUrl,
	},
	features: {
		text: 'links.overview',
		to: '/tools',
	},
	watch_all_features: {
		to: '/tools',
	},
	see_partners: {
		to: '/partners',
	},
	watch_all_features_pricing_page: {
		text: 'links.watch_all_features',
		onClick: e => {
			e.preventDefault()
			scrollToId('feature-table')
		},
	},
	mail: {
		text: 'common.mail_us',
		to: 'mailto:help@eitje.app',
	},
	time_registration: {
		to: '/urenregistratie',
	},
	communication: {
		to: '/communicatie',
	},
	more_features: {
		text: 'links.more',
		to: '/tools',
	},
	try_28_days: {
		to: createAccountUrl,
	},
}

export const linkMapping = _.mapValues(links, (value, key) => ({
	text: `links.${key}`,
	...value,
}))
