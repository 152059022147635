import {Title, Logos, EitjeButton, linkMapping, Layout} from '/src/components'

export const PartnerLogos = ({partners, ...rest}) => {
	const logos = partners.map(p => p.logoBlock).slice(0, 9)

	return (
		<Layout direction="vertical" horizontal="center" gap={20} {...rest}>
			<Title t="common.other_partners" center />
			<Logos logos={logos} marginTop={12} />
			<EitjeButton colorSet="solid" link={linkMapping.all_partners.to} t={linkMapping.all_partners.text} />
		</Layout>
	)
}
