import React from 'react'

import {Text as TextComponent, Title as TitleComponent} from '@eitje/web_components'
import '../../styles/overwrites/text.less'
import utils from '@eitje/utils'
import {getPopoutComponent} from './helpers'

export const Text = props => {
	let {large, small, mini, size, trigger = 'hover', className, uppercase, fontFamily = 'raleway', lowercase} = props

	const PopoutComponent = getPopoutComponent(props)

	const classNames = utils.makeCns(className, lowercase && 'eitje-lowercase', uppercase && 'eitje-uppercase')

	const htmlTag = large || size === 'large' ? 'h5' : small || size === 'small' || mini || size === 'mini' ? 'h6' : 'p'
	return (
		<TextComponent
			HtmlTag={htmlTag}
			trigger={trigger}
			{...props}
			fontFamily={fontFamily}
			PopoutComponent={PopoutComponent}
			className={classNames}
		/>
	)
}

export const Title = props => {
	const {large, small, mini, size, fontFamily = 'raleway'} = props
	const htmlTag = large || size === 'large' ? 'h1' : small || size === 'small' ? 'h3' : mini || size === 'mini' ? 'h4' : 'h2'
	return <TitleComponent HtmlTag={htmlTag} {...props} fontFamily={fontFamily} />
}
