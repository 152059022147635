import {Layout, getResp} from '../layout'
import {RichText} from '../rich_text'
import {Title} from '../text'
import './styles.less'

export const Paragraph = ({title, text, ...rest}) => {
	return (
		<Layout
			className="text-block"
			gap={8}
			direction="vertical"
			horizontal="center"
			maxWidth={getResp(['100%', 800])}
			margin="- 28"
			{...rest}
		>
			<Title t={title} center />
			<RichText content={text} fullWidth />
		</Layout>
	)
}
