import {PricingCard} from '.'
import {useWindowWidth} from '/src/contexts'
import {Layout} from '../layout'

const pricingMapping = [
	{
		type: 'planning',
		bullets: ['rosters', 'availability', 'communication', 'exports'],
	},
	{
		type: 'hours',
		bullets: ['register_hours', 'pass_hours', 'integrations', 'balance'],
		highlight: true,
	},
	{
		type: 'clock',
		bullets: ['clock_app', 'clock_codes', 'meals'],
	},
]

export const PricingCards = props => {
	const {mobile, desktop} = useWindowWidth()
	const inlineStyles = {alignItems: !mobile && 'stretch'}
	return (
		<Layout direction={desktop ? 'horizontal' : 'vertical'} gap={32} style={inlineStyles} {...props}>
			{pricingMapping.map((item, i) => (
				<PricingCard key={i} item={item} />
			))}
		</Layout>
	)
}
