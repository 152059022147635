import {Icon, useHover} from '@eitje/web_components'
import {Text} from '../text'
import {Layout} from '../layout'
import utils from '@eitje/utils'

export const DefaultListPickerTrigger = ({title, disabled, small}) => {
	const {hoverActions} = useHover()

	const classNames = utils.makeCns(utils.makeCnVariants('default-list-picker-trigger', small && 'small'), disabled && 'disabled')

	return (
		<Layout {...hoverActions} className={classNames} height={45} padding="10 16" horizontal="spaceBetween" gap={8}>
			<Text large truncate bold fontSize={18}>
				{title}
			</Text>
			<Icon name="chevron-down" size={12} />
		</Layout>
	)
}
