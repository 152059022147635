import utils from '@eitje/utils'
import {useI18n} from '/src/i18n'
import {EitjeButton, Link, TryEitjeButton, Layout, getResp, Title, Text} from '/src/components'
import {useWindowWidth} from '/src/contexts'
import {packageCosts, createAccountUrl} from '/src/constants'
import {formatCurrency} from '/src/helpers'
import './styles.less'

const inlineStyles = {flex: 1}

export const PricingCard = ({item}) => {
	const {type, bullets, highlight} = item
	const {tablet, desktop} = useWindowWidth()
	const classNames = utils.makeCnVariants('pricing-card-content', highlight && 'highlighted')
	const [t] = useI18n()
	const pricePrefix = type === 'planning' ? '' : '+ '
	const price = `${pricePrefix}${formatCurrency(packageCosts[type].incremental)}`
	const namespace = `pricing_card.cards.${type}`

	const handleScroll = e => {
		e.preventDefault()
		const el = document.querySelector('.feature-table')
		el?.scrollIntoView({behavior: 'smooth'})
	}
	return (
		<Link className="pricing-card" displayContents to={createAccountUrl} data-heap-id={`pricing-card-${type}`}>
			<Layout
				direction="vertical"
				className={classNames}
				border
				maxWidth={tablet && 330}
				padding={getResp(['28 24', '28 24', '32'])}
				style={inlineStyles}
				colorSet
				hasHover
				gap={[12, 12, 20]}
			>
				<Layout direction="vertical">
					<Title small t={`${namespace}.title`} />
					<Text className="pricing-card-subtitle" darkGrey t={`${namespace}.subtitle`} />
				</Layout>
				<Layout>
					<Title>{price}</Title>
					<Layout
						onClick={e => {
							// TODO: This should happen in infopopout once we can update the web components again
							e.stopPropagation()
							e.preventDefault()
						}}
					>
						<Text
							className="eitje-text-per-teammember"
							trigger="hover"
							darkGrey
							chat
							popoutTitle="popouts.active_team_members.title"
							popoutBody={t('popouts.active_team_members.text')}
						>
							/ {t('common.team_member')}
						</Text>
					</Layout>
				</Layout>
				<Layout direction="vertical" marginTop={12}>
					<Text large bold t={`${namespace}.heading`} />
					<ul>
						{bullets?.map((el, i) => (
							<li key={i}>
								<Text t={`pricing_card.bullet_points.${el}`} />
							</li>
						))}
						<li>
							<Text className="eitje-link" onClick={handleScroll} t="common.and_more" />
						</li>
					</ul>
				</Layout>
				{/* Set link to `undefined` to stop propagation, the whole card is clickable */}
				<TryEitjeButton width="100%" link={undefined} />
				{highlight && desktop && (
					<EitjeButton
						height="large"
						colorSet="solid"
						className="pricing-card-content-highlight"
						t="common.most_chosen"
						link={createAccountUrl}
					/>
				)}
			</Layout>
		</Link>
	)
}
