import {Children, cloneElement, isValidElement} from 'react'
import {HeaderAndFooter} from './header_and_footer'
import {useWindowWidth} from '/src/contexts'
import {MAX_PAGE_WIDTH} from '/src/constants'
import {ErrorBoundary, Layout, getResp, SEO, Banner} from '/src/components'
import {requireProps} from '/src/helpers'

const PageBody = ({children, banner, bannerProps, ...rest}) => {
	children = Children.map(Children.toArray(children).filter(Boolean), child => {
		return isValidElement(child) ? cloneElement(child, rest) : child
	})
	const {windowWidth} = useWindowWidth()
	const horizontalMargin = (windowWidth < 380 ? 6 : 16) * 2

	return (
		<Layout
			className="page-layout-content popout-boundary"
			direction="vertical"
			width={`calc(100% - ${horizontalMargin}px)`}
			maxWidth={getResp(['100%', MAX_PAGE_WIDTH])}
			margin="0 auto"
			horizontal="center"
			gap={0}
			padding="- - 80 -"
		>
			{banner && <Banner marginTop={[40, 60]} type={banner} {...bannerProps} />}
			{children}
		</Layout>
	)
}

export const PageLayout = ({name, seoTitle, seoDescription, hideFooter, ...rest}) => {
	requireProps('PageLayout', {name})
	const className = `${name}-page`

	// TO DO: temporarily uncomment below check, since we have to fill all data in Contentful
	// requireProps(`PageLayout ${className}`, {seoTitle, seoDescription})

	return (
		<ErrorBoundary>
			<div className={className}>
				<HeaderAndFooter hideFooter={hideFooter}>
					<SEO title={seoTitle} description={seoDescription} />
					<PageBody {...rest} />
				</HeaderAndFooter>
			</div>
		</ErrorBoundary>
	)
}
