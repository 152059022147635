import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {Youtube, FAQ, Banner, PageLayout, PerksHighlight, Layout, ProductPagesBanner} from '/src/components'

const PERKS = [
	[
		{type: 'monthly', hide_cta: true},
		{type: 'lowest_price', hide_cta: true},
		{type: 'integrations', hide_cta: true},
	],
]

const InnerPage = ({data}) => {
	let {faq} = formatData({data})

	return (
		<>
			<Layout maxWidth={800} width="100%">
				<Youtube video="eitje-intro" marginTop={40} />
			</Layout>
			<ProductPagesBanner type="discover_all_of_eitje" hide="all_features" />
			<Banner type="no_hidden_costs" marginTop={100} gap={32}>
				<PerksHighlight highlights={PERKS[0]} />
			</Banner>
			<FAQ marginTop={80} items={faq} />
			<Banner type="try_for_free" marginTop={120} />,
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})

	return (
		<PageLayout name="video" banner="video" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		contentfulPage(slug: {eq: "video"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
