import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {PartnerCards, Banner, FAQ, PageLayout, PerksHighlight} from '/src/components'

const PRODUCTS = [['integrations_no_extra_charge', 'request_integration', 'open_api']]

const InnerPage = ({data}) => {
	const {partner, partnerTypeNew, frequentlyAskedQuestion: frequentlyAskedQuestions} = data
	return (
		<>
			<Banner type="integrations" marginTop={60} />
			<PerksHighlight highlights={PRODUCTS[0]} marginTop={60} />
			<PartnerCards marginTop={[60, 100]} partners={partner} partnerTypes={partnerTypeNew} />
			<Banner type="become_partner" marginTop={[60, 100]} />
			<FAQ margin="100 -" items={frequentlyAskedQuestions} />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})
	return (
		<PageLayout name="partners" {...seo}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ContentfulPartners($locale: String) {
		contentfulPage(slug: {eq: "partners"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}
		allContentfulPartner(sort: {orderSelection: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					id
					name
					slug
					logoBlock {
						gatsbyImageData
					}
					partnerTypeNew {
						name
					}
				}
			}
		}
		allContentfulPartnerTypeNew(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					name
				}
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
