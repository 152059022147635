import {useRef} from 'react'
import {useI18n} from '/src/i18n'
import {Layout} from '../layout'
import {useDelayedAnimating} from './hooks'
import './styles.less'
import utils from '@eitje/utils'
import {mobile as mobileSize} from '../../styles/breakpoints.module.less'
import {useWindowWidth} from '/src/contexts'

const titles = ['planning', 'registering_hours', 'clocking', 'communicate', 'onboarding']

export const TitleCarousel = () => {
	const [t] = useI18n()
	const isAnimating = useDelayedAnimating()
	const wordCarousel = titles.map(title => <WordSpan key={title} textKey={title} isAnimating={isAnimating} isCarousel />)
	const staticWord = <WordSpan textKey={titles[0]} isAnimating={isAnimating} isCarousel={false} />
	const {mobile} = useWindowWidth()

	// Set a maxWidth and overflow hidden to contain invisible elements that are part of the text animation, enlarging the page.
	// Only apply this logic to mobiles, since the font is bigger on desktop and the bigger elements don't cause any issue.
	const preventHorizontalScroll = mobile && {maxWidth: mobileSize, style: {overflow: 'hidden'}}

	return (
		<Layout
			marginTop={60}
			className="title-carousel"
			direction="vertical"
			height={[110, 150]}
			horizontal="center"
			{...preventHorizontalScroll}
		>
			<h1 className="title-carousel">
				<div className="word-carousel">
					{wordCarousel}
					{staticWord}
				</div>
			</h1>
			<h1 className="title-carousel-static-text">{t('common.with_ease')}</h1>
		</Layout>
	)
}

const WordSpan = ({textKey, isAnimating, isCarousel = false}) => {
	const [t] = useI18n()

	const className = utils.makeCnVariants(
		'eitje-verb',
		isCarousel && 'moving',
		((isAnimating && !isCarousel) || (!isAnimating && isCarousel)) && 'hide',
	)

	return <span className={className}>{t(`common.${textKey}`)}</span>
}
