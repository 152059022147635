import {TextBlock} from '.'
import {useWindowWidth} from '/src/contexts'
import {useI18n} from '/src/i18n'
import {Layout, getResp} from '../../layout'
import {Link} from '../../link'

export const FeatureSnippets = ({textBlocks, buttonLink, label, buttonText}) => {
	const {mobile} = useWindowWidth()
	const [t] = useI18n()

	if (!textBlocks?.length || mobile) return null
	return (
		<Layout className="feature-snippets" direction="vertical" horizontal="center" gap={16} marginTop={64}>
			<Layout gap={24} horizontal="center" wrap>
				{textBlocks.map(block => (
					<TextBlock {...block} width={208} />
				))}
			</Layout>
			{buttonLink && (
				<Link
					to={buttonLink}
					text={t('common.see_all_from', {
						page: (label || buttonText).toLowerCase(),
					})}
				/>
			)}
		</Layout>
	)
}
