export const bannerMapping = {
	planning: {
		steps: ['make', 'duplicate', 'drag', 'publish'],
	},
	hours: {
		steps: ['edit', 'approve', 'export_hours'],
	},
	communication: {
		steps: ['share', 'like', 'respond'],
	},
	onboarding: {
		steps: ['think_up', 'create', 'publish', 'onboarding'],
	},
	exports: {
		steps: ['choose_data', 'move_columns', 'edit_layout', 'export'],
	},
	financial: {
		steps: ['control', 'adjust'],
	},
	clocking: {
		steps: ['enter_code', 'clock_in', 'start_shift'],
	},
}
