import {useState, useCallback} from 'react'

export const useSwipe = actions => {
	const [startTouch, setStartTouch] = useState({x: 0, y: 0})

	const handleTouchStart = useCallback(e => {
		const touch = e.touches[0]
		setStartTouch({x: touch.clientX, y: touch.clientY})
	}, [])

	const handleTouchMove = useCallback(
		e => {
			if (!startTouch.x || !startTouch.y) {
				return
			}

			const moveEndX = e.changedTouches[0].clientX
			const moveEndY = e.changedTouches[0].clientY
			const diffX = startTouch.x - moveEndX
			const diffY = startTouch.y - moveEndY

			if (Math.abs(diffX) > Math.abs(diffY)) {
				// Horizontal movement
				if (diffX > 0) {
					actions.onSwipeLeft?.()
				} else {
					actions.onSwipeRight?.()
				}
			} else {
				// Vertical movement
				if (diffY > 0) {
					actions.onSwipeUp?.()
				} else {
					actions.onSwipeDown?.()
				}
			}

			// Reset
			setStartTouch({x: 0, y: 0})
		},
		[actions, startTouch],
	)

	return {
		onTouchStart: handleTouchStart,
		onTouchMove: handleTouchMove,
	}
}

export default useSwipe
