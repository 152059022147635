import {intercomUrl} from '../constants'
import {useI18n} from '/src/i18n'
import {navigate} from 'gatsby'

export const appStoreUrl = 'https://apps.apple.com/:locale:/app/eitje/id1268811198'
export const googlePlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.eitjeapp&hl=:locale:'
export const appStoreClockSystem = 'https://apps.apple.com/:locale:/app/eitjeopdevloer/id1446451276'
export const googlePlayStoreClockSystem = 'https://play.google.com/store/apps/details?id=com.eitjeopdevloer&hl=:locale:'
const EXTERNAL_URLS = ['http', 'www.', 'tel:', 'mailto:']

export const useLocaleUrl = url => {
	const [, intl] = useI18n()
	const locale = intl.locale

	if (!url) return {}

	const external = EXTERNAL_URLS.some(id => url.includes(id))
	const target = external ? '_blank' : '_self'

	let navigateUrl
	if (target == '_blank') {
		navigateUrl = () => {
			window.open(url, '_blank')
		}
	} else {
		navigateUrl = () => {
			navigate(url, 'self')
			window.scrollTo(0, 0)
		}
	}

	// Case 1: insert locale at predetermined locations through the assigned placeholder
	if (url.includes(':locale:')) {
		url = url.replace(':locale:', locale)
		return {url, target, navigateUrl}
	}

	// Case 2: simply build internal urls with prefixed /en
	const englishUrl = locale != 'nl'
	const alreadyTranslated = url.includes('/en/')

	if (englishUrl && !alreadyTranslated && !external) {
		url = `/${locale}${url}`
		return {url, target, navigateUrl}
	}

	// Case 3: just don't do anything with the url at all and simply return it
	return {url, target, navigateUrl}
}

export const useLocaleIntercomUrl = () => {
	const {url} = useLocaleUrl(intercomUrl)
	return url
}

export const useLocaleGooglePlayUrl = ({clock} = {}) => {
	const _url = clock ? googlePlayStoreClockSystem : googlePlayStoreUrl
	const {url} = useLocaleUrl(_url)
	return url
}

export const useLocaleAppStoreUrl = ({clock} = {}) => {
	const _url = clock ? appStoreClockSystem : appStoreUrl
	const {url} = useLocaleUrl(_url)
	return url
}
