import {useEffect, useRef} from 'react'
import {Layout} from '../layout'
import './styles.less'

export const NumberInput = ({onChange, value}) => {
	const inputRef = useRef()

	useEffect(() => {
		inputRef.current.style.width = `calc(${inputRef.current.value.length + 'ch'} + 18px)`
	}, [value])

	const handleChange = e => {
		let val = parseInt(e.target.value)
		if (isNaN(val)) val = 0
		onChange(val)
	}

	return (
		<Layout border padding="10 16" className="number-input">
			<input
				ref={inputRef}
				value={value.toString()} // We convert to string to prevent leading zero's
				type="number"
				onChange={handleChange}
			/>
		</Layout>
	)
}
