import React, {useEffect, useRef} from 'react'
import {useI18n} from '/src/i18n'
import {Layout} from '../layout'
import {useWindowWidth} from '/src/contexts'
import {placeholders} from './placeholders'
import './styles.less'

const VIDEOS = {
	'eitje-intro': '6dGFIvQ0Q7Q',
	planning: 'OxDo1eN_ErA',
	hours: 'T8klooesW5g',
	communication: '0QLSDvyRgcw',
	onboarding: 'IOSMf_o7FcY',
	exports: 'tv4kSvmD6kc',
	financial: '7gWwUHjHl2c',
	clocking: 'z_VA6E5o18s',
}

const enable = 1

export const Youtube = ({autoplay, video, ...rest}) => {
	const videoId = VIDEOS[video] || video
	const playerRef = useRef(null)
	const playerInstanceRef = useRef(null)
	const [, intl] = useI18n()
	const locale = intl.locale

	const {desktop} = useWindowWidth()

	const loadYouTubeIframeAPI = () => {
		if (typeof window === 'undefined') return

		if (window.YT && window.YT.Player) {
			initializePlayer()
			return
		}

		const tag = document.createElement('script')
		tag.src = 'https://www.youtube.com/iframe_api'
		const firstScriptTag = document.getElementsByTagName('script')[0]
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

		window.onYouTubeIframeAPIReady = initializePlayer // This function is called by the YouTube API once it's ready
	}

	const initializePlayer = () => {
		// Clean up any existing player instance before creating a new one
		if (playerInstanceRef.current) {
			playerInstanceRef.current.destroy()
		}

		playerInstanceRef.current = new window.YT.Player(playerRef.current, {
			width: '100%',
			height: '100%',
			videoId: videoId,
			playerVars: {
				autoplay: autoplay && enable,
				mute: autoplay && enable, // For autoplay to work on most browsers, audio must be muted by default
				modestbranding: enable, // Shows bare minumum youtube related content in the embedded window
				cc_load_policy: enable, // Turns captions on by default
				cc_lang_pref: locale, //  Sets the language for the captions shown in the video
				loop: autoplay && enable,
				playlist: autoplay && videoId, // For the loop to work, the playlist should be equal to the current video id
			},
		})
	}

	useEffect(() => {
		loadYouTubeIframeAPI()

		// Cleanup function to destroy player instance on component unmount or before reinitializing
		return () => {
			if (playerInstanceRef.current) {
				playerInstanceRef.current.destroy()
			}
		}
	}, [videoId, locale, autoplay, desktop])

	return (
		<Layout
			className="youtube-wrapper"
			width="100%"
			style={{
				background: `url(${placeholders[video]})`,
				backgroundSize: '100% auto',
			}}
			{...rest}
		>
			<div id="player" ref={playerRef}></div>
		</Layout>
	)
}
