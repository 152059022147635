import {useWindowWidth} from '/src/contexts'
import {Image, Layout} from '/src/components'
import './index.less'

const imageNames = ['office abel', 'team happy', 'office street', 'office clara']

export const VacancyImages = ({assets}) => {
	const {mobile} = useWindowWidth()
	const images = imageNames.map(name => assets.find(a => a.title === name)) // We use a map + find instead of filter to ensure the order
	return (
		<Layout className="vacancy-images" childrenPerRow={mobile ? 1 : 2} gap={16} marginTop={100}>
			{images.map(image => (
				<Layout>
					<div className="image-container">
						<Image width="100%" image={image} />
					</div>
				</Layout>
			))}
		</Layout>
	)
}
