import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {
	Quotes,
	Logos,
	PartnerLogosBanner,
	Title,
	FAQ,
	PhoneRow,
	Banner,
	Layout,
	PerksHighlight,
	ProductHighlights,
	ProductHighlightCarousel,
	PageLayout,
} from '/src/components'
import {useI18n} from '/src/i18n'
import {formatSector, templatePageSeoProps} from '/src/helpers'

const PERKS = [['support', 'integrations', 'no_hidden_costs']]

const PRODUCTS = [
	['planning-roster'],
	['time-registration-financial', 'communication-news'],
	['knowledge-create', 'availability-period', 'clock-out', 'exports-build', 'financial-revenue'],
]

const InnerPage = ({data}) => {
	let {name, namePlural, slug, quotes, previews, mobilePreviews, trustAmount = 300, clients} = data.sector
	const {faq, partnerLogos, products} = formatData({data})
	const [t] = useI18n()
	const productProps = {highlights: products, previews, sectorName: slug}
	const sectorLogos = clients?.map(c => c.logo) // don't use formatData() since each sector page has its own set of relevant clients

	return (
		<>
			<Banner type="sector_main" sector={name} marginTop={[40, 60]} />
			<ProductHighlights items={PRODUCTS[0]} {...productProps} />
			<PerksHighlight highlights={PERKS[0]} marginTop={[40, 120]} />
			<ProductHighlights items={PRODUCTS[1]} {...productProps} coloringOffsetAmount={3} />
			<ProductHighlightCarousel items={PRODUCTS[2]} horizontalInversed {...productProps} />
			<Banner type="contact" marginTop={[80, 80, 120]} />
			<Banner type="show_mobile_app" marginTop={[100, 140]} />
			<PhoneRow marginTop={[24, 60]} elements={mobilePreviews} />
			<Layout horizontal="center" direction="vertical" gap={[24, 28]} marginTop={[80, 180]}>
				<Title center>
					{t('common.trusted_by', {
						amount: trustAmount,
						name: namePlural || name,
					})}
				</Title>
				<Logos logos={sectorLogos} />
				<Quotes size="small" items={quotes} marginTop={0} />
			</Layout>
			<PartnerLogosBanner logos={partnerLogos} />
			<FAQ marginTop={[80, 120]} items={faq} />
			<Banner type="try_for_free" marginTop={120} />
		</>
	)
}

const Page = ({data}) => {
	const {seo} = formatData({data})
	const name = formatSector(data?.sector?.name)
	const seoProps = templatePageSeoProps(name, seo)

	const [t] = useI18n()
	return (
		<PageLayout name="sector" {...seoProps}>
			<InnerPage data={data} />
		</PageLayout>
	)
}

export const query = graphql`
	query ($slug: String!, $locale: String) {
		contentfulPage(slug: {eq: "sector"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
		}

		contentfulSector(node_locale: {eq: $locale}, slug: {eq: $slug}) {
			name
			namePlural
			slug
			previews {
				gatsbyImageData
				title
			}
			trustAmount
			mobilePreviews {
				gatsbyImageData
			}
			quotes {
				name
				jobTitle
				image {
					gatsbyImageData
				}
				quote {
					quote
				}
			}
			clients {
				logo {
					gatsbyImageData
				}
			}
		}
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					highlights
					shortHighlights
					cta
					name
					buttonLink
					buttonText
				}
			}
		}
		allContentfulPartner(sort: {orderSelection: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					orderSelection
					logoBlock {
						gatsbyImageData
					}
				}
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
