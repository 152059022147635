import {useRef} from 'react'
import {Icon, Colors} from '@eitje/web_components'
import {Layout, getResp} from '../layout'
import {Text} from '../text'
import './styles.less'

function hasAccess(inputTier, currentTier, columns) {
	const inputTierIndex = columns.indexOf(inputTier)
	const currentTierIndex = columns.indexOf(currentTier)
	return currentTierIndex >= inputTierIndex
}

export const Row = ({feature, columns, columnWidths}) => {
	const tableRowRef = useRef()
	const {tooltipText, tooltipTitle, name, tier} = feature

	// to keep things light 'n quit, don't show the title if the title is already present in the text
	const skipTitle = tooltipTitle === name
	const showTitle = tooltipText && !skipTitle

	return (
		<Layout ref={tableRowRef} className="feature-table-row" borderBottom width="100%" gap={0}>
			<Layout grow gap={8} padding={`16 - 16 ${tooltipText ? 12 : 30}`}>
				<Text
					popoutTitle={showTitle && tooltipTitle}
					popoutBody={tooltipText}
					truncateTooltip={false}
					boundaryElement={tableRowRef?.current}
					placement="top"
					t={name}
				/>
			</Layout>
			{columns.map(column => (
				<Column column={column} columnWidths={columnWidths} columns={columns} tier={tier} />
			))}
		</Layout>
	)
}

export const Column = ({tier, column, columns, columnWidths}) => {
	return (
		<Layout width={getResp(columnWidths)} horizontal="center" padding="16 12">
			{hasAccess(tier, column, columns) && <Icon size={getResp([8, 8, 12])} name="dot" fill={Colors.mediumGreen} />}
		</Layout>
	)
}
