import React from 'react'
import {EitjeButton as EitjeButtonComponent} from '@eitje/web_components'
import {useWindowWidth} from '/src/contexts'
import {LinkButton} from './buttons'
import './styles.less'

export * from './buttons'

export const EitjeButton = props => {
	let {height = 'large', link, ...rest} = props
	const {mobile} = useWindowWidth()

	if (link) return <LinkButton {...props} />

	if (mobile && height === 'large') height = 'regular'

	return <EitjeButtonComponent fontFamily="raleway" height={height} {...rest} />
}
