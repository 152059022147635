import {useState} from 'react'
import {ButtonsNavigation} from '../buttons_navigation'
import {Layout, getResp} from '../layout'
import {PartnerCard} from './partner_card'
import {Title} from '../text'
import {ApiButton, IntegrationChatButton} from '../eitje_button/buttons'
import {useWindowWidth} from '/src/contexts'
import {apiDocsUrl} from '../../constants'
import {showSupportChat} from '../../helpers'
import {useI18n} from '/src/i18n'
import _ from 'lodash'

const SPOTLIGHT_PARTNER = 'Tebi'

export const PartnerCards = ({partners, partnerTypes, ...rest}) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const [t] = useI18n()
	const {desktop, mobile} = useWindowWidth()

	const elements = [{name: 'common.all_integrations'}, ...partnerTypes, {name: 'common.miscellaneous'}]

	const items = partners.filter(p => {
		if (activeIndex === 0 || (activeIndex === elements.length - 1 && !p.partnerTypeNew)) return true
		return elements[activeIndex]?.name === p.partnerTypeNew?.name
	})

	const spotlightPartner = partners.find(p => p.name === SPOTLIGHT_PARTNER)
	const buttonCardStyles = {
		width: getResp(['100%', 'calc(50% - 10px)', '-']),
	}

	const [nonSpotlight, spotlight] = _.partition(items, p => p.name !== SPOTLIGHT_PARTNER)
	const sortedPartners = [...nonSpotlight, ...spotlight]

	const partnerCards = sortedPartners.map((partner, i) => <PartnerCard key={i} item={partner} to={`/partners/${partner.slug}`} />)

	return (
		<Layout gap={8} horizontal="center" direction="vertical" width="100%" className="partner-cards" {...rest}>
			<Title t="common.our_partners" />
			<Layout gap={20} direction="vertical" width={getResp(['100%', 680, 790])}>
				<ButtonsNavigation activeIndex={activeIndex} onChange={setActiveIndex} elements={elements} baseColor="blue" />
				<Layout childrenEqual={desktop} wrap={!desktop} gap={mobile ? 12 : 20} width="100%">
					<PartnerCard
						item={spotlightPartner}
						to={`/partners/${spotlightPartner.slug}`}
						spotlight
						topText="common.spotlight_partner"
						width="100%"
					/>
					<PartnerCard
						topText="common.missing_integration"
						button={<IntegrationChatButton height="small" />}
						animation="light-bulb"
						style={buttonCardStyles}
						onClick={() => showSupportChat(t('intercom.integrations_text'))}
						data-heap-id="support-chat-integrations"
					/>
					<PartnerCard
						topText="common.build_integration"
						button={<ApiButton height="small" />}
						animation="hammer"
						style={buttonCardStyles}
						to={apiDocsUrl}
					/>
				</Layout>
				<Layout gap={20} childrenPerRow={getResp([1, 2, 3])} width="100%">
					{partnerCards}
				</Layout>
			</Layout>
		</Layout>
	)
}
