export const formatData = ({data, debug}) => {
	const seoTitle = data.page?.seoTitle
	const seoDescription = data.page?.seoDescription

	const newData = {
		rawData: data,
		products: data.featureHighlight || [],
		clientLogos: data.client?.map(client => client.logo) || [],
		faq: data.frequentlyAskedQuestion || [],
		quotes: data.page?.quotes || [],
		tiles: data.page?.productTiles || [],
		minorFeatures: data.page?.featureList || [],
		asset: data.asset,
		seoTitle,
		seoDescription,
		seo: {seoTitle, seoDescription},
		paragraphs: data.textPage?.textBlocks || [],
		blogs: data.blogPost || [],

		// invoke .map() with a safe operator because some `partner` entities in Contentful aren't array and are used
		// for different purposes, such as on the show page for a single business partner
		partnerLogos: data.partner?.map?.(partner => partner.logoBlock) || [],
	}

	if (debug) console.log(newData)
	return newData
}
