import React from 'react'
import {graphql, Link} from 'gatsby'

const Dev404Page = ({data}) => {
  const pages = data.allSitePage.nodes
  return (
    <div>
      <h1>404: Page Not Found</h1>
      <p>Here are some pages you might be looking for:</p>
      <ul>
        {pages.map(page => (
          <li key={page.path}>
            <Link to={page.path}>{page.path}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const query = graphql`
  query {
    allSitePage {
      nodes {
        path
      }
    }
  }
`

export default Dev404Page
