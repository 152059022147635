import _ from 'lodash'
import {packageCosts} from '../../constants'

const discounts = {
	100: 0.25,
	250: 0.5,
	500: 0.75,
	750: 1,
}

export const DISCOUNT_THRESHOLD = _.keys(discounts)[0]

export const getDiscountAmount = memberAmount => {
	const discountsArray = _.toPairs(discounts)
	const discount = _.findLast(discountsArray, ([key]) => memberAmount > parseInt(key))

	const [, discountPercentage] = discount || []
	return discountPercentage || 0
}

export const getFutureDiscount = (memberAmount, tierDiscount) => {
	const discount = Object.entries(discounts).find(([key]) => {
		const amount = parseInt(key)
		return (tierDiscount || memberAmount > amount * 0.75) && memberAmount < amount + 1
	})

	const [discountMemberAmount, discountPercentage] = discount || []
	return {
		futureDiscount: discountPercentage || 0,
		futureDiscountAmount: discount ? parseInt(discountMemberAmount) + 1 - memberAmount : 0,
	}
}

export const calculateAmountPerMonth = ({currentDiscount, charityStatus, activeTeamMembers, packageType, discount = true}) => {
	const packageCost = packageCosts[packageType].price
	const price = activeTeamMembers * packageCost
	if (!discount) return price
	return charityStatus ? price * 0.5 : price - activeTeamMembers * currentDiscount
}
