import React from 'react'
import {Link as IntlLink} from 'gatsby-plugin-react-intl'
import utils from '@eitje/utils'
import {useI18n} from '/src/i18n'
import './styles.less'
import _ from 'lodash'

const externalLinkCheck = to => {
	if (!to || to.startsWith('/')) return
	const cleanedUrl = to.replace(/^(https?:\/\/)?(www\.)?/, '')
	const urlParts = cleanedUrl.split('?')[0].split('/')
	return urlParts[0] !== 'eitje.app'
}

export const Link = props => {
	let {onClick, to, children, text, style, customSize, noInitialStyles, displayContents, undecorated, ...rest} = props
	const [t] = useI18n()
	const styles = {cursor: 'pointer', ...style}
	let content = children || t(text)
	if (typeof content === 'string') content = utils.capitalize(content)

	const classNames = utils.makeCns(
		utils.makeCnVariants(
			'eitje-link',
			noInitialStyles && 'no-initial-styles',
			undecorated && 'undecorated',
			displayContents && 'display-contents',
		),
		customSize && 'custom-link-size',
	)

	const handleClick = e => {
		e.preventDefault()
		onClick(e)
	}

	let condOpts = {}
	if (onClick) condOpts.onClick = handleClick

	if (_.isString(to)) {
		const questionIndex = to.indexOf('?')

		// When there is a query param, we add the trailing slash before the "?"
		if (questionIndex > -1) {
			if (to[questionIndex - 1] !== '/') {
				to = to.slice(0, questionIndex) + '/' + to.slice(questionIndex)
			}
		} else {
			if (!to.endsWith('/') && !to.includes('mailto:')) to = to + '/'
		}
	}

	let isExternal = externalLinkCheck(to)
	if (isExternal)
		return (
			<a href={to} className={classNames} target="_blank" rel="noopener noreferrer" style={styles} data-heap-id={rest['data-heap-id']}>
				{content}
			</a>
		)

	return (
		<IntlLink
			{...condOpts}
			className={classNames}
			target={!onClick && '_blank'}
			rel="noreferrer"
			to={onClick ? undefined : to}
			style={styles}
			data-heap-id={rest['data-heap-id']}
		>
			{content}
		</IntlLink>
	)
}
