import utils from '@eitje/utils'
import {useWindowWidth} from '/src/contexts'
import {getResp, EitjeButton, TryEitjeButton, Layout} from '/src/components'

const BUTTON_WIDTH = 230
const GAP = 8

export const FeatureButtons = ({hide}) => {
	const {mobile} = useWindowWidth()
	const hideArray = utils.alwaysArray(hide)
	const currentPath = typeof window !== 'undefined' && window.location.pathname

	const buttons = [
		{
			key: 'all_features',
			iconLeft: 'checked',
			link: '/tools',
		},
		{key: 'planning', iconLeft: 'calendar', t: 'planning', link: '/rooster'},
		{
			key: 'hours',
			iconLeft: 'clock',
			link: '/urenregistratie',
		},
		{
			key: 'communication',
			iconLeft: 'chat',
			link: '/communicatie',
		},
		{
			key: 'onboarding',
			iconLeft: 'gym-barbell',
			link: '/inwerken',
		},
		{
			key: 'clocking',
			iconLeft: 'hourglass',
			link: '/kloksysteem',
		},
		{
			key: 'mobile_app',
			iconLeft: 'smartphone',
			link: '/mobiele-app',
		},
		{
			key: 'financial',
			iconLeft: 'euro',
			link: '/financiele-analyse',
		},
		{
			key: 'exports',
			iconLeft: 'graph-arrow',
			link: '/excel-exporteren',
		},
	].filter(b => !hideArray.includes(b.key) && !currentPath?.includes?.(b.link))

	const buttonsPerRow = getResp([2, 3, 4])
	const nonMobileWidth = BUTTON_WIDTH * buttonsPerRow + GAP * (buttonsPerRow - 1)

	return (
		<Layout direction="vertical" gap={GAP} horizontal="center" width={mobile ? '100%' : nonMobileWidth}>
			<Layout wrap gap={GAP} width="100%" childrenPerRow={buttonsPerRow}>
				{buttons.map(button => (
					<EitjeButton {...button} t={`links.${button.key}`} />
				))}
			</Layout>
			<TryEitjeButton iconLeft="paper-plane" width={mobile && '100%'} />
		</Layout>
	)
}
