import {PageLayout, ProductPagesBanner, VideoBanner, FAQ, Banner, Try28DaysButton} from '/src/components'
import {requireProps} from '/src/helpers'

// Usually we don't use any marginBottom, only tops, but in this case it's useful since the element which should receive the marginTop
// is not standardized within the ProductPageLayout but passed as the first child instead.
const videoProps = {layoutProps: {margin: '- - 100 -'}}

export const ProductPageLayout = ({name, video = true, faq, blobColor, scrollLinks, children, ...rest}) => {
	requireProps('ProductPageLayout', {name})

	const bannerProps = {
		marginTop: [40, 60],
		type: name,
		titleLarge: true,
		subtitle: false,
		buttons: <Try28DaysButton />,
		scrollLinks,
	}

	return (
		<PageLayout {...rest} name={name}>
			{video ? <VideoBanner {...videoProps} blobColor={blobColor} {...bannerProps} /> : <Banner {...bannerProps} />}
			{children}
			<ProductPagesBanner />
			{faq && <FAQ marginTop={100} items={faq} />}
			<EndOfPageBanner />
		</PageLayout>
	)
}

const EndOfPageBanner = () => {
	// Need to be in a different component, otherwise it somehow does not received its own unique props.
	return <Banner type="try_for_free" marginTop={120} />
}
