import {useState} from 'react'
import {useEventListener, useMutationObserver} from '@eitje/react-hooks'

// We initiaite with this height because it takes a couple of ms to calculate the actual height
// This gives us a good starting point for painting the DOM
const DEFAULT_HEADER_HEIGHT = 58

export const useApplicationHeadersHeight = () => {
	const [headerHeight, setHeaderHeight] = useState(DEFAULT_HEADER_HEIGHT)

	const hasDocument = typeof document !== 'undefined'
	const headers = hasDocument && document.querySelector('.application-headers')

	const computeOffset = () => {
		setHeaderHeight(headers?.clientHeight)
	}

	useEventListener('resize', computeOffset)

	useMutationObserver({
		mutationFunction: computeOffset,
		observeOptions: {childList: true, subtree: true},
		element: headers,
	})

	return Math.round(headerHeight)
}

export const useBodyHeight = () => {
	const headerHeight = useApplicationHeadersHeight()
	return `calc(100vh - ${headerHeight}px`
}
