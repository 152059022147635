export const getCarouselElements = asset => {
	const images = _.keyBy(asset, 'title')
	return [
		{
			name: 'links.planning',
			image: images['planning-roster'],
		},
		{
			name: 'links.time_registration',
			image: images['hours-export-payroll'],
		},
		{name: 'links.clock_system', image: images['hours-clock-out']},
		{name: 'links.financial', image: images['planning-financial']},
		{
			name: 'links.communication',
			image: images['communication-news-hospitality'],
		},
	]
}
