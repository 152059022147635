import {BlogArticleCard, PARENT_GAP} from '.'
import {getResp} from '../layout'

const getWidthMinusGap = percentages => {
	return getResp(percentages.map((percentage, index) => (index === 0 ? '100%' : `calc(${percentage}% - ${PARENT_GAP / (index + 1)}px)`)))
}

export const FeaturedBlogArticleCard = props => {
	const imageWidth = getWidthMinusGap([100, 50, 66.6])
	const contentWidth = getWidthMinusGap([100, 50, 33.3])
	return <BlogArticleCard featured imageWidth={imageWidth} contentWidth={contentWidth} {...props} />
}
