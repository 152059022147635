import 'regenerator-runtime/runtime' // fix for https://github.com/gatsbyjs/gatsby/discussions/26015
import {graphql} from 'gatsby'
import {formatData, withFlattenedResponse} from '/src/helpers'
// ^ Standard page imports – should auto import..?
import {
	Banner,
	FAQ,
	ProductPageLayout,
	PerksBanner,
	ProductHighlights,
	Quotes,
	ProductPagesBanner,
	FeatureButtons,
	CheckedItemList,
	ProductTiles,
	ClientLogosBanner,
	VideoBanner,
} from '/src/components'

const PERKS = [
	[
		{type: 'monthly', hide_cta: true},
		{type: 'lowest_price', hide_cta: true},
		{type: 'integrations', hide_cta: true},
	],
]

const PRODUCTS = [
	['share_messages', 'reminders'],
	['happy_support', 'eitje_chat', 'team_groupchat'],
	['newsfeed_events', 'polls'],
]

const PageChildren = ({data}) => {
	const {products, quotes, clientLogos, tiles, minorFeatures} = formatData({data})

	return (
		<>
			<PerksBanner type="no_hidden_costs" highlights={PERKS[0]} marginTop={0} />
			<ProductHighlights highlights={products} items={PRODUCTS[0]} coloringOffsetAmount={2} />
			<Quotes items={quotes[0]} />
			<ProductHighlights highlights={products} items={PRODUCTS[1]} coloringOffsetAmount={3} />
			<Quotes items={quotes[1]} />
			<ProductHighlights highlights={products} items={PRODUCTS[2]} coloringOffsetAmount={1} />
			<ClientLogosBanner logos={clientLogos} />
			<ProductTiles type="extensive_communication" items={tiles} />
			<CheckedItemList type="other_communication_features" items={minorFeatures} />
		</>
	)
}

const Page = ({data}) => {
	const {seo, faq} = formatData({data})

	return (
		<ProductPageLayout name="communication" blobColor="orange" {...seo} faq={faq}>
			<PageChildren data={data} />
		</ProductPageLayout>
	)
}

export const query = graphql`
	query ($locale: String) {
		allContentfulFeatureHighlight(filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					uuid
					highlights
					shortHighlights
					cta
					name
					asset {
						gatsbyImageData
					}
					preview {
						gatsbyImageData
					}
					previews {
						gatsbyImageData
					}
					mobilePreview {
						gatsbyImageData
					}
					label
					buttonLink
					buttonText
					paragraph {
						paragraph
					}
				}
			}
		}
		allContentfulFrequentlyAskedQuestion(sort: {order: ASC}, filter: {node_locale: {eq: $locale}, type: {eq: "general"}}) {
			edges {
				node {
					question {
						question
					}
					answer {
						raw
					}
				}
			}
		}
		allContentfulClient(sort: {order: ASC}, filter: {node_locale: {eq: $locale}}) {
			edges {
				node {
					logo {
						gatsbyImageData
					}
				}
			}
		}
		contentfulPage(slug: {eq: "communication"}, node_locale: {eq: $locale}) {
			seoTitle
			seoDescription
			productTiles {
				title
				subtitle
				icon
			}
			featureList
			quotes {
				name
				jobTitle
				image {
					gatsbyImageData
				}
				quote {
					quote
				}
			}
		}
	}
`

export default withFlattenedResponse(Page)
