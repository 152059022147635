import QueryString from 'query-string'
import {getStoredQueryParams} from '../hooks/use_store_or_query_params'
import utils from '@eitje/utils'
const saveQueryParams = () => {
	const qp = QueryString.parse(window.location.search)
	const storedParams = getStoredQueryParams()
	const combinedQueryParams = {...qp, ...storedParams}
	if (utils.exists(combinedQueryParams)) {
		localStorage.setItem('queryParams', JSON.stringify(combinedQueryParams))
	}
}

export default saveQueryParams
