import React, {useState, useRef} from 'react'
import {useHover} from '@eitje/react-hooks'
import {Layout} from '../layout'
import {Title} from '../text'
import {ButtonsNavigation} from '../buttons_navigation'
import {DotsNavigation} from '../dots_navigation'
import {useWasInViewport} from '../product_highlight/hooks'
import {useCarouselSwipe, useSetAutomatic, useStartResetCarousel} from './hooks'

export const Carousel = ({
	title,
	elements,
	interval = 5000,
	marginTop,
	Component,
	baseColor,
	navigation = 'buttons-navigation',
	sharedProps,
}) => {
	const [isAutomatic, setIsAutomatic] = useState(true)
	const carouselRef = useRef()
	const intervalRef = useRef()
	const wasInViewport = useWasInViewport(carouselRef)

	const [activeIndex, setActiveIndex] = useState(0)
	const {hoverActions, isHovering: isComponentHovered} = useHover()

	const hooksProps = {
		interval,
		isComponentHovered,
		isAutomatic,
		setIsAutomatic,
		intervalRef,
		elements,
		activeIndex,
		setActiveIndex,
		wasInViewport,
	}

	const swipeHandlers = useCarouselSwipe(hooksProps)
	useStartResetCarousel(hooksProps)
	useSetAutomatic(hooksProps)

	const currentProps = {...sharedProps, ...elements[activeIndex]}

	const handleChange = index => {
		setActiveIndex(index)
		setIsAutomatic(false)
	}

	const sharedNavProps = {
		activeIndex,
		elements,
		onChange: handleChange,
	}

	return (
		<Layout ref={carouselRef} className="carousel" marginTop={marginTop} direction="vertical" horizontal="center" gap={16}>
			{title && <Title t={title} center />}
			<Layout direction="vertical" gap={12}>
				{navigation === 'buttons-navigation' && <ButtonsNavigation baseColor={baseColor} {...sharedNavProps} />}
				<Layout {...swipeHandlers} {...hoverActions} width="100%" direction="vertical">
					<Component {...currentProps} />
					{navigation === 'dots' && <DotsNavigation marginTop={12} {...sharedNavProps} />}
				</Layout>
			</Layout>
		</Layout>
	)
}
