import {Layout, getResp} from '../layout'
import {Text} from '../text'
import {featureList} from './mappings'
import {useWindowWidth} from '/src/contexts'

export const SeoBlocks = props => (
	<Layout childrenPerRow={getResp([1, 2, 3])} gap={12} {...props}>
		{featureList.map(SeoBlock)}
	</Layout>
)

const inlineStyles = {
	backgroudColor: 'white',
}

const inlineStylesText = {
	textAlign: 'justify',
}

const SeoBlock = ({title, info}) => {
	const {mobile} = useWindowWidth()
	const height = mobile ? undefined : 172 // don't set a fixed height if there's only 1 child per row

	return (
		<Layout
			direction="vertical"
			horizontal="center"
			height={height}
			parentGap={16}
			gap={4}
			padding={16}
			border
			colorSet="bordered-grey"
			borderRadius="large"
			style={inlineStyles}
		>
			<Text fontSize={14} bold t={title} />
			<Text style={inlineStylesText} fontSize={12} darkGrey t={info} />
		</Layout>
	)
}
