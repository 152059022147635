import React, {createContext, useState, useEffect, useContext} from 'react'
import * as breakpointsModule from '../styles/breakpoints.module.less'

const WindowWidthContext = createContext(null)

export const WindowWidthProvider = ({children}) => {
	const [windowWidth, setWindowWidth] = useState(1200)

	useEffect(() => {
		if (typeof document !== 'undefined') {
			const handleResize = () => {
				setWindowWidth(document.documentElement.clientWidth)
			}

			handleResize()
			window.addEventListener('resize', handleResize)

			return () => window.removeEventListener('resize', handleResize)
		}
	}, [])

	const breakpoints = Object.fromEntries(Object.entries(breakpointsModule).map(([key, value]) => [key, value.replace('px', '')]))

	const value = {
		windowWidth,
		smallMobile: windowWidth < breakpoints.mobile,
		mobile: windowWidth < breakpoints.tablet,
		tablet: windowWidth >= breakpoints.tablet && windowWidth < breakpoints.desktop,
		desktop: windowWidth >= breakpoints.desktop,
	}

	return <WindowWidthContext.Provider value={value}>{children}</WindowWidthContext.Provider>
}

export const useWindowWidth = () => {
	const context = useContext(WindowWidthContext)
	return context
}
